import React, { CSSProperties, useMemo } from 'react'
import { styles } from './Image.styles'

export const lorumPicsum = (width?: number, height?: number) =>
    `https://picsum.photos/${width ?? 200}/${height ?? 200}?z=${Math.random() * 1000}`

type Props = {
    src?: string
    alt?: string
    overrides?: CSSProperties
    useLoremPicsum?: boolean
    loremSettings?: { width: number; height: number }
}
export const Image: React.FC<Props> = ({ src, alt, overrides, useLoremPicsum, loremSettings }) => {
    const _src = useMemo(
        () => (useLoremPicsum ? lorumPicsum(loremSettings?.width, loremSettings?.height) : src),
        [useLoremPicsum],
    )
    return <img src={_src} alt={alt} style={styles.parent.provide(null, overrides)} />
}

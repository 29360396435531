import React, { useLayoutEffect, useState } from 'react'
import { createStore, mutator, Mutator } from '@/lib/zust'
import _ from 'lodash'

/* ---------------------------- TYPE DEFINITIONS ---------------------------- */
export type BreakpointLimits = {
    widescreen: { name: 'widescreen'; min: number }
    desktop: { name: 'desktop'; min: number; max: number }
    mobile: { name: 'mobile'; max: number }
}

export type BPComparators = Record<keyof typeof breakpointLimits, (w: number, h: number) => boolean>

export const breakpointLimits: BreakpointLimits = {
    widescreen: { name: 'widescreen', min: 1921 },
    desktop: { name: 'desktop', min: 1137, max: 1920 },
    mobile: { name: 'mobile', max: 1137 },
}

export const bpComparators: BPComparators = {
    widescreen: (w) => w >= breakpointLimits.widescreen.min,
    desktop: (w) => w <= breakpointLimits.desktop.max && w >= breakpointLimits.desktop.min,
    mobile: (w) => w <= breakpointLimits.mobile.max,
}

export interface Store {
    width: number | null
    height: number | null
    breakpoint: BreakpointLimits[keyof BreakpointLimits]['name'] | null
    set: Mutator<Store, Partial<Store>>
}

export const SFrameStore = createStore<Store>(
    (set, get) => ({
        width: null,
        height: null,
        breakpoint: null,
        set: mutator(set, (draft, payload) => {
            draft = Object.assign(draft, payload)
        }),
    }),
    'SFrame',
)

const getWindowSize = () => {
    const widths = [window.innerWidth]
    const heights = [window.innerHeight]

    if (window.screen?.width) {
        widths.push(window.screen?.width)
    }

    if (window.screen?.height) {
        heights.push(window.screen.height)
    }

    const width = Math.min(...widths)
    const height = Math.min(...heights)
    return [width, height]
}

const getBreakpoint = (width: number, height: number) => {
    let breakpoint: Store['breakpoint'] = null

    Object.keys(bpComparators).every((key) => {
        if (bpComparators[key as keyof BPComparators](width, height)) {
            breakpoint = key as Store['breakpoint']
            return false
        }
        return true
    })

    return breakpoint
}

const MEDIA_QUERY_DEBOUNCE = 0
export const useMediaQuery = () => {
    const [update, setUpdate] = useState(Date.now())
    const set = SFrameStore.use.set()
    useLayoutEffect(() => {
        const handleResize = _.debounce<any>(() => {
            const currentWidth = SFrameStore.getState().width
            const currentHeight = SFrameStore.getState().height
            const currentBreakpoint = SFrameStore.getState().breakpoint

            const [newWidth, newHeight] = getWindowSize()

            if (currentWidth === newWidth && currentHeight === newHeight) return
            const newBreakpoint = getBreakpoint(newWidth, newHeight)

            if (currentBreakpoint === newBreakpoint) return
            set({ breakpoint: newBreakpoint, width: newWidth, height: newHeight })
            setUpdate(Date.now())
        }, MEDIA_QUERY_DEBOUNCE)

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
}

export const useGetBreakpoint = () => SFrameStore.use.breakpoint()

const userAgent: string = navigator.userAgent || navigator.vendor

export const isMobileDevice = (): boolean => {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
    return regexs.some((b) => userAgent.match(b))
  }
  
  const isTabletDevice = (): boolean => {
    const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
    return regex.test(userAgent.toLowerCase())
  }

  export const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice()
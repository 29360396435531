import React, { useMemo, useState } from 'react'
import { styles } from './MobileVerify.styles'
import adminBackground from '@/assets/admin-background.png'
import { Image } from '@/shared/styleComponents'
import { Text } from '@/shared/styleComponents'
import { Input } from '@/shared/components/'
import { PrimaryButton } from '@/shared/components/'
import QrReader from 'react-qr-scanner'
import { graphqlRequestClient, useVerifyAdmissionJwtMutation, Ticket } from '@/graphql/'
import { Spinner } from '@/shared/styleComponents/spinner/Spinner'
import { ErrorIcon, SuccessIcon } from '../../icons'
import _ from 'lodash'
import { ComponentStatus } from '../../Verify'

const isStatus = (status: Props['status'], check: Props['status'][] | Props['status']) => {
    if (_.isArray(check)) {
        return check.includes(status)
    }

    return status === check
}
type Props = {
    status: ComponentStatus
    qrScanner: React.ReactElement
    verifiedTicket?: Ticket | null
    onScanAnother?: React.MouseEventHandler
    onVerify?: React.MouseEventHandler
    onSwitchCamera?: React.MouseEventHandler
}
export const MobileVerify: React.FC<Props> = ({ status, qrScanner, verifiedTicket, onScanAnother, onVerify, onSwitchCamera }) => {
    return (
        <React.Fragment>
            <div className="body" style={styles.parent.provide()}>
                <div
                    className="qr-scanner"
                    style={styles.qrScanner.parent.provide({ isIdle: isStatus(status, 'idle') })}
                >
                    {isStatus(status, ['idle', 'qrdetected']) && (
                        <div
                            className="aim"
                            style={styles.qrScanner.aim.provide({ qrDetected: isStatus(status, 'qrdetected') })}
                        />
                    )}

                    {isStatus(status, ['idle', 'qrdetected']) ? (
                        qrScanner
                    ) : isStatus(status, 'loading') ? (
                        <Spinner size={70} color="var(--white)" secondaryColor="rgba(255, 255, 255, 0.1)" />
                    ) : isStatus(status, 'verfied') ? (
                        <div style={styles.status.icon.provide()}>
                            <SuccessIcon />
                            <Text fontSize={40} color="var(--success)" fontFamily="dms-bold">
                                Valid Ticket
                            </Text>
                        </div>
                    ) : isStatus(status, 'used') ? (
                        <div style={styles.status.icon.provide()}>
                            <ErrorIcon />
                            <Text fontSize={40} color="var(--danger)" fontFamily="dms-bold">
                                Ticket Used
                            </Text>
                        </div>
                    ) : isStatus(status, 'invalid') ? (
                        <div style={styles.status.icon.provide()}>
                            <ErrorIcon />
                            <Text fontSize={40} color="var(--danger)" fontFamily="dms-bold">
                                Invalid Ticket
                            </Text>
                        </div>
                    ) : (
                        isStatus(status, 'samecode') && (
                            <div style={styles.status.icon.provide()}>
                                <ErrorIcon />
                                <Text fontSize={40} color="var(--danger)" fontFamily="dms-bold" textAlign="center">
                                    Same Code Scanned
                                </Text>
                            </div>
                        )
                    )}
                </div>
                <div className="controls" style={styles.controls.parent.provide()}>
                    {isStatus(status, ['idle', 'qrdetected']) ? (
                        <PrimaryButton
                            disabled={isStatus(status, 'idle')}
                            text="Verify Ticket"
                            overrides={{
                                parent: styles.controls.button?.parent?.provide(),
                                text: styles.controls.button.text?.provide(),
                            }}
                            animation={styles.controls.button.parent?.provideAnimation()}
                            onClick={(e) => onScanAnother?.(e)}
                        />
                    ) : (
                        <PrimaryButton
                            text="Scan Another"
                            overrides={{
                                parent: styles.controls.button?.parent?.provide(),
                                text: styles.controls.button.text?.provide(),
                            }}
                            animation={styles.controls.button.parent?.provideAnimation()}
                            onClick={(e) => onScanAnother?.(e)}
                        />
                    )}
                        <PrimaryButton
                            text="Switch Camera"
                            overrides={{
                                parent: styles.controls.subButton?.parent?.provide(),
                                text: styles.controls.subButton.text?.provide(),
                            }}
                            animation={styles.controls.subButton.parent?.provideAnimation()}
                            onClick={(e) => onSwitchCamera?.(e)}
                        />
                </div>
            </div>
        </React.Fragment>
    )
}

import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useInfiniteQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Map: any;
};

export type Artist = {
  __typename?: 'Artist';
  accountActive?: Maybe<Scalars['Boolean']>;
  avatarURL?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Maybe<Collection>>>;
  coverURL?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  followers?: Maybe<Array<Maybe<Artist>>>;
  followersCount?: Maybe<Scalars['Int']>;
  followings?: Maybe<Array<Maybe<Artist>>>;
  followingsCount?: Maybe<Scalars['Int']>;
  hasFollowed?: Maybe<Scalars['Boolean']>;
  instagram?: Maybe<Scalars['String']>;
  isEmailCaptured?: Maybe<Scalars['Boolean']>;
  isEthProfile?: Maybe<Scalars['Boolean']>;
  isTwitterVerified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  publicKey: Scalars['ID'];
  soundcloud?: Maybe<Scalars['String']>;
  spotify?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
};

export type ArtistsQueryInput = {
  keyword: Scalars['String'];
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
};

export type ArtistsResult = PagingResult & {
  __typename?: 'ArtistsResult';
  result: Array<Artist>;
  totalItems: Scalars['Int'];
};

export type BridgeMetadata = {
  __typename?: 'BridgeMetadata';
  chainId?: Maybe<Scalars['Int']>;
  originalMetaData?: Maybe<Scalars['Map']>;
  originalMetadataFormat?: Maybe<Scalars['String']>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  artist?: Maybe<Artist>;
  attributesList?: Maybe<Array<CollectionAttributes>>;
  category?: Maybe<NftCategory>;
  creators?: Maybe<Array<Maybe<NftCreator>>>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<NftFile>>>;
  genre?: Maybe<Scalars['String']>;
  hasLiked: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  likeCount?: Maybe<Scalars['Int']>;
  listingState?: Maybe<CollectionWhitelistListingState>;
  mint: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nfts?: Maybe<NfTsResult>;
  playCount?: Maybe<Scalars['Int']>;
  stats: CollectionStats;
  symbol?: Maybe<Scalars['String']>;
  totalNfts: Scalars['Int'];
  uri?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};


export type CollectionNftsArgs = {
  input?: InputMaybe<NftQueryInput>;
};

export type CollectionAttributes = {
  __typename?: 'CollectionAttributes';
  trait: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum CollectionSortBy {
  Floor = 'FLOOR',
  ListedTime = 'LISTED_TIME',
  SellOrderCount = 'SELL_ORDER_COUNT',
  Volume = 'VOLUME'
}

export type CollectionStats = {
  __typename?: 'CollectionStats';
  average?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  listedItem: Scalars['Int'];
  volume?: Maybe<Scalars['Float']>;
};

export enum CollectionWhitelistListingState {
  Listed = 'LISTED',
  Pending = 'PENDING'
}

export type CollectionsQueryInput = {
  artist?: InputMaybe<Scalars['String']>;
  ascending?: InputMaybe<Scalars['Boolean']>;
  collectionMints?: InputMaybe<Array<Scalars['String']>>;
  genre?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  listedOnly?: InputMaybe<Scalars['Boolean']>;
  maxFloorPrice?: InputMaybe<Scalars['Float']>;
  minFloorPrice?: InputMaybe<Scalars['Float']>;
  sample?: InputMaybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  sortBy?: InputMaybe<CollectionSortBy>;
  whitelistedOnly?: InputMaybe<Scalars['Boolean']>;
};

export type CollectionsResult = PagingResult & {
  __typename?: 'CollectionsResult';
  result: Array<Collection>;
  totalItems: Scalars['Int'];
};

export type CreatePostCommentInput = {
  description?: InputMaybe<Scalars['String']>;
};

export type CreatePostInput = {
  description?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
};

export type CreateTimedCommentInput = {
  audioTimestamp: Scalars['Int'];
  collectionMint: Scalars['String'];
  description: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  availableTicketCount: Scalars['Int'];
  currency: Scalars['String'];
  description: Scalars['String'];
  eventDate: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['String'];
  ticketCollectionMint: Scalars['String'];
  totalTicketCount: Scalars['Int'];
};

export type FollowQueryInput = {
  artist: Scalars['String'];
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
};

export type LoginChallenge = {
  __typename?: 'LoginChallenge';
  jwt?: Maybe<Scalars['String']>;
  signData?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  burnNFT?: Maybe<Scalars['Boolean']>;
  buy?: Maybe<Scalars['Boolean']>;
  cancel?: Maybe<Scalars['Boolean']>;
  createComment: PostComment;
  createPost: Post;
  createProfileAdmin?: Maybe<Scalars['Boolean']>;
  createStore?: Maybe<Scalars['Boolean']>;
  createTicket?: Maybe<Scalars['Boolean']>;
  createTimedComment: TimedComment;
  createWhitelisted?: Maybe<Scalars['Boolean']>;
  deleteComment?: Maybe<Scalars['Boolean']>;
  deleteCommentAdmin?: Maybe<Scalars['Boolean']>;
  deletePost?: Maybe<Scalars['Boolean']>;
  deletePostAdmin?: Maybe<Scalars['Boolean']>;
  followArtist?: Maybe<Scalars['Boolean']>;
  indexCollection?: Maybe<Scalars['Boolean']>;
  indexNFT?: Maybe<Scalars['Boolean']>;
  likeCollection?: Maybe<Scalars['Boolean']>;
  likePost?: Maybe<Scalars['Boolean']>;
  list?: Maybe<Scalars['Boolean']>;
  playCollection?: Maybe<Scalars['Boolean']>;
  refreshEventTicketOwner?: Maybe<Scalars['Boolean']>;
  requestAdmissionJWT?: Maybe<Scalars['String']>;
  requestLoginChallenge?: Maybe<LoginChallenge>;
  requestTicket?: Maybe<Scalars['String']>;
  submitLoginChallenge?: Maybe<Scalars['String']>;
  unfollowArtist?: Maybe<Scalars['Boolean']>;
  unlikeCollection?: Maybe<Scalars['Boolean']>;
  unlikePost?: Maybe<Scalars['Boolean']>;
  updateComment: PostComment;
  updateCommentAdmin?: Maybe<Scalars['Boolean']>;
  updatePost: Post;
  updatePostAdmin?: Maybe<Scalars['Boolean']>;
  updateProfile: Artist;
  updateProfileAdmin?: Maybe<Scalars['Boolean']>;
  verifyAdmissionJWT?: Maybe<Ticket>;
};


export type MutationBurnNftArgs = {
  mint: Scalars['String'];
};


export type MutationCreateCommentArgs = {
  input: CreatePostCommentInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreateTicketArgs = {
  eventId: Scalars['String'];
  mints: Array<Scalars['String']>;
};


export type MutationCreateTimedCommentArgs = {
  input?: InputMaybe<CreateTimedCommentInput>;
};


export type MutationDeleteCommentArgs = {
  postCommentId: Scalars['String'];
};


export type MutationDeletePostArgs = {
  postId: Scalars['String'];
};


export type MutationFollowArtistArgs = {
  artist: Scalars['String'];
};


export type MutationIndexCollectionArgs = {
  mint: Scalars['String'];
};


export type MutationIndexNftArgs = {
  mint: Scalars['String'];
};


export type MutationLikeCollectionArgs = {
  collectionMint: Scalars['String'];
};


export type MutationLikePostArgs = {
  postId: Scalars['String'];
};


export type MutationPlayCollectionArgs = {
  mint: Scalars['String'];
};


export type MutationRefreshEventTicketOwnerArgs = {
  eventId: Scalars['String'];
};


export type MutationRequestAdmissionJwtArgs = {
  mint: Scalars['String'];
};


export type MutationRequestLoginChallengeArgs = {
  publicKey: Scalars['String'];
};


export type MutationRequestTicketArgs = {
  eventId: Scalars['String'];
};


export type MutationSubmitLoginChallengeArgs = {
  jwt: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationUnfollowArtistArgs = {
  artist: Scalars['String'];
};


export type MutationUnlikeCollectionArgs = {
  collectionMint: Scalars['String'];
};


export type MutationUnlikePostArgs = {
  postId: Scalars['String'];
};


export type MutationUpdateCommentArgs = {
  input: UpdatePostCommentInput;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationVerifyAdmissionJwtArgs = {
  jwt: Scalars['String'];
  operatorSecret: Scalars['String'];
};

export type Nft = {
  __typename?: 'NFT';
  artist?: Maybe<Artist>;
  attributes?: Maybe<Scalars['Map']>;
  bridgeMetadata?: Maybe<BridgeMetadata>;
  category?: Maybe<NftCategory>;
  collection?: Maybe<Collection>;
  creators?: Maybe<Array<Maybe<NftCreator>>>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<NftFile>>>;
  image?: Maybe<Scalars['String']>;
  mint: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orderEvents?: Maybe<Array<OrderEvent>>;
  sellOrder?: Maybe<SellOrder>;
  sellerFeeBasisPoints?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type NftAttributesInput = {
  trait: Scalars['String'];
  value: Scalars['String'];
};

export enum NftCategory {
  Audio = 'audio',
  Html = 'html',
  Image = 'image',
  Music = 'music',
  Video = 'video',
  Vr = 'vr'
}

export type NftCreator = {
  __typename?: 'NFTCreator';
  address?: Maybe<Scalars['String']>;
  share?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type NftFile = {
  __typename?: 'NFTFile';
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type NftQueryInput = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  attributes?: InputMaybe<Array<NftAttributesInput>>;
  limit?: Scalars['Int'];
  listedOnly?: InputMaybe<Scalars['Boolean']>;
  skip?: Scalars['Int'];
  sortBy?: InputMaybe<NftSortBy>;
};

export enum NftSortBy {
  ListedTime = 'LISTED_TIME',
  Name = 'NAME',
  Price = 'PRICE'
}

export type NfTsResult = PagingResult & {
  __typename?: 'NFTsResult';
  result: Array<Nft>;
  totalItems: Scalars['Int'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  buyer?: Maybe<Artist>;
  createdAt: Scalars['DateTime'];
  eventType: OrderEventType;
  mint: Scalars['String'];
  nft: Nft;
  price: Scalars['Float'];
  seller: Artist;
  signature: Scalars['ID'];
};

export enum OrderEventType {
  Cancel = 'cancel',
  Execute = 'execute',
  New = 'new'
}

export type PagingResult = {
  totalItems: Scalars['Int'];
};

export type Post = {
  __typename?: 'Post';
  artist?: Maybe<Artist>;
  commentCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  hasLiked?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  pinned?: Maybe<Scalars['Boolean']>;
  pinnedAt?: Maybe<Scalars['DateTime']>;
};

export type PostComment = {
  __typename?: 'PostComment';
  artist?: Maybe<Artist>;
  commentCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  hasLiked?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  likeCount?: Maybe<Scalars['Int']>;
};

export type PostCommentsQueryInput = {
  limit?: Scalars['Int'];
  parentId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  skip?: Scalars['Int'];
};

export type PostCommentsResult = PagingResult & {
  __typename?: 'PostCommentsResult';
  result: Array<PostComment>;
  totalItems: Scalars['Int'];
};

export type PostsQueryInput = {
  artist: Scalars['String'];
  limit?: Scalars['Int'];
  pinned?: InputMaybe<Scalars['Boolean']>;
  skip?: Scalars['Int'];
};

export type PostsResult = PagingResult & {
  __typename?: 'PostsResult';
  result: Array<Post>;
  totalItems: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  artist?: Maybe<Artist>;
  artists?: Maybe<Array<Maybe<Artist>>>;
  collections: CollectionsResult;
  comments: PostCommentsResult;
  event?: Maybe<Event>;
  events?: Maybe<Array<Maybe<Event>>>;
  followers: ArtistsResult;
  followings: ArtistsResult;
  me: Artist;
  nfts: NfTsResult;
  nftsByMints: Array<Nft>;
  posts: PostsResult;
  storeTransactions?: Maybe<StoreTransactionResponse>;
  ticket?: Maybe<Ticket>;
  timedComments?: Maybe<Array<Maybe<TimedComment>>>;
  whitelists?: Maybe<Array<Maybe<Whitelist>>>;
};


export type QueryArtistArgs = {
  publicKey: Scalars['String'];
};


export type QueryArtistsArgs = {
  input: ArtistsQueryInput;
};


export type QueryCollectionsArgs = {
  input?: InputMaybe<CollectionsQueryInput>;
};


export type QueryCommentsArgs = {
  input: PostCommentsQueryInput;
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryFollowersArgs = {
  input: FollowQueryInput;
};


export type QueryFollowingsArgs = {
  input: FollowQueryInput;
};


export type QueryNftsArgs = {
  collectionMint: Scalars['String'];
  input?: InputMaybe<NftQueryInput>;
};


export type QueryNftsByMintsArgs = {
  mints: Array<Scalars['String']>;
};


export type QueryPostsArgs = {
  input: PostsQueryInput;
};


export type QueryStoreTransactionsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
  storeId: Scalars['String'];
};


export type QueryTicketArgs = {
  mint: Scalars['String'];
};


export type QueryWhitelistsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  mint?: InputMaybe<Scalars['String']>;
  pending?: InputMaybe<Scalars['Boolean']>;
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type SellOrder = {
  __typename?: 'SellOrder';
  createdAt: Scalars['DateTime'];
  mint: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  signature: Scalars['ID'];
};

export type StoreTransactionResponse = {
  __typename?: 'StoreTransactionResponse';
  events: Array<OrderEvent>;
  total: Scalars['Float'];
};

export type Ticket = {
  __typename?: 'Ticket';
  event: Event;
  hasSold: Scalars['Boolean'];
  lastOwner?: Maybe<Artist>;
  mint: Scalars['ID'];
  nft?: Maybe<Nft>;
  price?: Maybe<Scalars['Float']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  usedBy?: Maybe<Artist>;
};

export type TimedComment = {
  __typename?: 'TimedComment';
  artist: Artist;
  audioTimestamp: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdatePostCommentInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdatePostInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imageURL?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateProfileInput = {
  avatarURL?: InputMaybe<Scalars['String']>;
  coverURL?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publicKey: Scalars['ID'];
  soundcloud?: InputMaybe<Scalars['String']>;
  spotify?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type Whitelist = {
  __typename?: 'Whitelist';
  collectionMint: Scalars['String'];
  creator: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  genre: Scalars['String'];
  state: WhitelistState;
  storeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  verified?: Maybe<Scalars['Boolean']>;
};

export enum WhitelistState {
  Listed = 'listed',
  Pending = 'pending'
}

export type GetArtistQueryVariables = Exact<{
  publicKey: Scalars['String'];
}>;


export type GetArtistQuery = { __typename?: 'Query', artist?: { __typename?: 'Artist', publicKey: string, name?: string | null, username?: string | null, accountActive?: boolean | null, avatarURL?: string | null, coverURL?: string | null, description?: string | null, verified?: boolean | null, twitter?: string | null, instagram?: string | null, spotify?: string | null, soundcloud?: string | null, website?: string | null, isEmailCaptured?: boolean | null, isEthProfile?: boolean | null } | null };

export type LoginChallengeMutationVariables = Exact<{
  publicKey: Scalars['String'];
}>;


export type LoginChallengeMutation = { __typename?: 'Mutation', requestLoginChallenge?: { __typename?: 'LoginChallenge', jwt?: string | null, signData?: string | null } | null };

export type SubmitLoginChallengeMutationVariables = Exact<{
  jwt: Scalars['String'];
  signature: Scalars['String'];
}>;


export type SubmitLoginChallengeMutation = { __typename?: 'Mutation', submitLoginChallenge?: string | null };

export type GetEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, name: string, description: string, eventDate: any, ticketCollectionMint: string, totalTicketCount: number, availableTicketCount: number, location: string, price: string } | null };

export type GetEventNftsQueryVariables = Exact<{
  collectionInput?: InputMaybe<CollectionsQueryInput>;
  NFTInput?: InputMaybe<NftQueryInput>;
}>;


export type GetEventNftsQuery = { __typename?: 'Query', collections: { __typename?: 'CollectionsResult', totalItems: number, result: Array<{ __typename?: 'Collection', mint: string, image?: string | null, nfts?: { __typename?: 'NFTsResult', totalItems: number, result: Array<{ __typename?: 'NFT', mint: string, name?: string | null, description?: string | null, image?: string | null }> } | null }> } };

export type GetTicketNfTsQueryVariables = Exact<{
  mints: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetTicketNfTsQuery = { __typename?: 'Query', nftsByMints: Array<{ __typename?: 'NFT', name?: string | null }> };

export type GetTicketQueryVariables = Exact<{
  mint: Scalars['String'];
}>;


export type GetTicketQuery = { __typename?: 'Query', ticket?: { __typename?: 'Ticket', usedAt?: any | null, nft?: { __typename?: 'NFT', name?: string | null } | null } | null };

export type GetTicketAndEventQueryVariables = Exact<{
  mint: Scalars['String'];
}>;


export type GetTicketAndEventQuery = { __typename?: 'Query', ticket?: { __typename?: 'Ticket', usedAt?: any | null, event: { __typename?: 'Event', id: string, name: string, description: string, eventDate: any, location: string, price: string, currency: string, imageUrl: string, ticketCollectionMint: string, totalTicketCount: number, availableTicketCount: number }, nft?: { __typename?: 'NFT', name?: string | null, mint: string } | null } | null };

export type RequestTicketMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type RequestTicketMutation = { __typename?: 'Mutation', requestTicket?: string | null };

export type RequestAdmissionJwtMutationVariables = Exact<{
  mint: Scalars['String'];
}>;


export type RequestAdmissionJwtMutation = { __typename?: 'Mutation', requestAdmissionJWT?: string | null };

export type VerifyAdmissionJwtMutationVariables = Exact<{
  jwt: Scalars['String'];
  operatorSecret: Scalars['String'];
}>;


export type VerifyAdmissionJwtMutation = { __typename?: 'Mutation', verifyAdmissionJWT?: { __typename?: 'Ticket', mint: string, hasSold: boolean, price?: number | null, usedAt?: any | null } | null };


export const GetArtistDocument = `
    query getArtist($publicKey: String!) {
  artist(publicKey: $publicKey) {
    publicKey
    name
    username
    accountActive
    avatarURL
    coverURL
    description
    verified
    twitter
    instagram
    spotify
    soundcloud
    website
    isEmailCaptured
    isEthProfile
  }
}
    `;
export const useGetArtistQuery = <
      TData = GetArtistQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetArtistQueryVariables,
      options?: UseQueryOptions<GetArtistQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetArtistQuery, TError, TData>(
      ['getArtist', variables],
      fetcher<GetArtistQuery, GetArtistQueryVariables>(client, GetArtistDocument, variables, headers),
      options
    );
export const useInfiniteGetArtistQuery = <
      TData = GetArtistQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetArtistQueryVariables,
      client: GraphQLClient,
      variables: GetArtistQueryVariables,
      options?: UseInfiniteQueryOptions<GetArtistQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetArtistQuery, TError, TData>(
      ['getArtist.infinite', variables],
      (metaData) => fetcher<GetArtistQuery, GetArtistQueryVariables>(client, GetArtistDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );

export const LoginChallengeDocument = `
    mutation LoginChallenge($publicKey: String!) {
  requestLoginChallenge(publicKey: $publicKey) {
    jwt
    signData
  }
}
    `;
export const useLoginChallengeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LoginChallengeMutation, TError, LoginChallengeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<LoginChallengeMutation, TError, LoginChallengeMutationVariables, TContext>(
      ['LoginChallenge'],
      (variables?: LoginChallengeMutationVariables) => fetcher<LoginChallengeMutation, LoginChallengeMutationVariables>(client, LoginChallengeDocument, variables, headers)(),
      options
    );
export const SubmitLoginChallengeDocument = `
    mutation SubmitLoginChallenge($jwt: String!, $signature: String!) {
  submitLoginChallenge(jwt: $jwt, signature: $signature)
}
    `;
export const useSubmitLoginChallengeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SubmitLoginChallengeMutation, TError, SubmitLoginChallengeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SubmitLoginChallengeMutation, TError, SubmitLoginChallengeMutationVariables, TContext>(
      ['SubmitLoginChallenge'],
      (variables?: SubmitLoginChallengeMutationVariables) => fetcher<SubmitLoginChallengeMutation, SubmitLoginChallengeMutationVariables>(client, SubmitLoginChallengeDocument, variables, headers)(),
      options
    );
export const GetEventDocument = `
    query GetEvent($id: String!) {
  event(id: $id) {
    id
    name
    description
    eventDate
    ticketCollectionMint
    totalTicketCount
    availableTicketCount
    location
    price
  }
}
    `;
export const useGetEventQuery = <
      TData = GetEventQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetEventQueryVariables,
      options?: UseQueryOptions<GetEventQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetEventQuery, TError, TData>(
      ['GetEvent', variables],
      fetcher<GetEventQuery, GetEventQueryVariables>(client, GetEventDocument, variables, headers),
      options
    );
export const useInfiniteGetEventQuery = <
      TData = GetEventQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetEventQueryVariables,
      client: GraphQLClient,
      variables: GetEventQueryVariables,
      options?: UseInfiniteQueryOptions<GetEventQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetEventQuery, TError, TData>(
      ['GetEvent.infinite', variables],
      (metaData) => fetcher<GetEventQuery, GetEventQueryVariables>(client, GetEventDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );

export const GetEventNftsDocument = `
    query GetEventNFTS($collectionInput: CollectionsQueryInput, $NFTInput: NFTQueryInput) {
  collections(input: $collectionInput) {
    totalItems
    result {
      mint
      image
      nfts(input: $NFTInput) {
        totalItems
        result {
          mint
          name
          description
          image
        }
      }
    }
  }
}
    `;
export const useGetEventNftsQuery = <
      TData = GetEventNftsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetEventNftsQueryVariables,
      options?: UseQueryOptions<GetEventNftsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetEventNftsQuery, TError, TData>(
      variables === undefined ? ['GetEventNFTS'] : ['GetEventNFTS', variables],
      fetcher<GetEventNftsQuery, GetEventNftsQueryVariables>(client, GetEventNftsDocument, variables, headers),
      options
    );
export const useInfiniteGetEventNftsQuery = <
      TData = GetEventNftsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetEventNftsQueryVariables,
      client: GraphQLClient,
      variables?: GetEventNftsQueryVariables,
      options?: UseInfiniteQueryOptions<GetEventNftsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetEventNftsQuery, TError, TData>(
      variables === undefined ? ['GetEventNFTS.infinite'] : ['GetEventNFTS.infinite', variables],
      (metaData) => fetcher<GetEventNftsQuery, GetEventNftsQueryVariables>(client, GetEventNftsDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );

export const GetTicketNfTsDocument = `
    query GetTicketNFTs($mints: [String!]!) {
  nftsByMints(mints: $mints) {
    name
  }
}
    `;
export const useGetTicketNfTsQuery = <
      TData = GetTicketNfTsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetTicketNfTsQueryVariables,
      options?: UseQueryOptions<GetTicketNfTsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetTicketNfTsQuery, TError, TData>(
      ['GetTicketNFTs', variables],
      fetcher<GetTicketNfTsQuery, GetTicketNfTsQueryVariables>(client, GetTicketNfTsDocument, variables, headers),
      options
    );
export const useInfiniteGetTicketNfTsQuery = <
      TData = GetTicketNfTsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetTicketNfTsQueryVariables,
      client: GraphQLClient,
      variables: GetTicketNfTsQueryVariables,
      options?: UseInfiniteQueryOptions<GetTicketNfTsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetTicketNfTsQuery, TError, TData>(
      ['GetTicketNFTs.infinite', variables],
      (metaData) => fetcher<GetTicketNfTsQuery, GetTicketNfTsQueryVariables>(client, GetTicketNfTsDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );

export const GetTicketDocument = `
    query GetTicket($mint: String!) {
  ticket(mint: $mint) {
    usedAt
    nft {
      name
    }
  }
}
    `;
export const useGetTicketQuery = <
      TData = GetTicketQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetTicketQueryVariables,
      options?: UseQueryOptions<GetTicketQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetTicketQuery, TError, TData>(
      ['GetTicket', variables],
      fetcher<GetTicketQuery, GetTicketQueryVariables>(client, GetTicketDocument, variables, headers),
      options
    );
export const useInfiniteGetTicketQuery = <
      TData = GetTicketQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetTicketQueryVariables,
      client: GraphQLClient,
      variables: GetTicketQueryVariables,
      options?: UseInfiniteQueryOptions<GetTicketQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetTicketQuery, TError, TData>(
      ['GetTicket.infinite', variables],
      (metaData) => fetcher<GetTicketQuery, GetTicketQueryVariables>(client, GetTicketDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );

export const GetTicketAndEventDocument = `
    query GetTicketAndEvent($mint: String!) {
  ticket(mint: $mint) {
    usedAt
    event {
      id
      name
      description
      eventDate
      location
      price
      currency
      imageUrl
      ticketCollectionMint
      totalTicketCount
      availableTicketCount
    }
    nft {
      name
      mint
    }
  }
}
    `;
export const useGetTicketAndEventQuery = <
      TData = GetTicketAndEventQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetTicketAndEventQueryVariables,
      options?: UseQueryOptions<GetTicketAndEventQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetTicketAndEventQuery, TError, TData>(
      ['GetTicketAndEvent', variables],
      fetcher<GetTicketAndEventQuery, GetTicketAndEventQueryVariables>(client, GetTicketAndEventDocument, variables, headers),
      options
    );
export const useInfiniteGetTicketAndEventQuery = <
      TData = GetTicketAndEventQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetTicketAndEventQueryVariables,
      client: GraphQLClient,
      variables: GetTicketAndEventQueryVariables,
      options?: UseInfiniteQueryOptions<GetTicketAndEventQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetTicketAndEventQuery, TError, TData>(
      ['GetTicketAndEvent.infinite', variables],
      (metaData) => fetcher<GetTicketAndEventQuery, GetTicketAndEventQueryVariables>(client, GetTicketAndEventDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );

export const RequestTicketDocument = `
    mutation RequestTicket($eventId: String!) {
  requestTicket(eventId: $eventId)
}
    `;
export const useRequestTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RequestTicketMutation, TError, RequestTicketMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RequestTicketMutation, TError, RequestTicketMutationVariables, TContext>(
      ['RequestTicket'],
      (variables?: RequestTicketMutationVariables) => fetcher<RequestTicketMutation, RequestTicketMutationVariables>(client, RequestTicketDocument, variables, headers)(),
      options
    );
export const RequestAdmissionJwtDocument = `
    mutation RequestAdmissionJWT($mint: String!) {
  requestAdmissionJWT(mint: $mint)
}
    `;
export const useRequestAdmissionJwtMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RequestAdmissionJwtMutation, TError, RequestAdmissionJwtMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RequestAdmissionJwtMutation, TError, RequestAdmissionJwtMutationVariables, TContext>(
      ['RequestAdmissionJWT'],
      (variables?: RequestAdmissionJwtMutationVariables) => fetcher<RequestAdmissionJwtMutation, RequestAdmissionJwtMutationVariables>(client, RequestAdmissionJwtDocument, variables, headers)(),
      options
    );
export const VerifyAdmissionJwtDocument = `
    mutation VerifyAdmissionJWT($jwt: String!, $operatorSecret: String!) {
  verifyAdmissionJWT(jwt: $jwt, operatorSecret: $operatorSecret) {
    mint
    hasSold
    price
    usedAt
  }
}
    `;
export const useVerifyAdmissionJwtMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VerifyAdmissionJwtMutation, TError, VerifyAdmissionJwtMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VerifyAdmissionJwtMutation, TError, VerifyAdmissionJwtMutationVariables, TContext>(
      ['VerifyAdmissionJWT'],
      (variables?: VerifyAdmissionJwtMutationVariables) => fetcher<VerifyAdmissionJwtMutation, VerifyAdmissionJwtMutationVariables>(client, VerifyAdmissionJwtDocument, variables, headers)(),
      options
    );
export interface ILock {
    trycode: () => Promise<void>
    catchcode: (e: any) => Promise<void> | void
    beforeRetryCode?: () => Promise<void>
    log?: boolean
    funcId?: string
}

export interface IAttmept {
    trycode: () => void
    catchcode: (e: any) => Promise<void> | void
    log?: boolean
    funcId?: string
}

export interface IAbortable {
    trycode: (previousRequest: AbortController) => Promise<void>
    catchcode: (e: any) => Promise<void> | void
    log?: boolean
    funcId?: string
}

export interface ILockSignature extends ILock {
    signature: string | number
}

export class RaceGate {
    isResponding: boolean
    requestController: AbortController
    signatureLocks: { [key: string | number]: boolean }
    retried: boolean
    constructor(private readonly name?: string) {
        this.isResponding = false
        this.retried = false
        this.signatureLocks = {}
        this.requestController = new AbortController()
    }

    async lock({ trycode, catchcode, beforeRetryCode, funcId = undefined, log = false }: ILock) {
        try {
            if (this.isResponding) {
                if (log) console.log('function id :', funcId, ' Rejected')
                return
            }
            this.isResponding = true
            if (log) console.log('function funcId :', funcId, ' Attempting')

            await trycode()
            this.isResponding = false

            if (log) console.log('function funcId :', funcId, ' Success')
        } catch (e: any) {
            if (beforeRetryCode != null && !this.retried) {
                this.retried = true
                this.isResponding = false
                await beforeRetryCode()
                await this.lock({ trycode, catchcode, beforeRetryCode, funcId, log })
                return
            }
            await catchcode(e)
            this.isResponding = false

            if (log) console.log('function funcId :', funcId, ' crashed')
        }
    }

    async lockToSignature({ trycode, catchcode, signature, funcId = undefined, log = false }: ILockSignature) {
        try {
            const isSignatureResponding = this.signatureLocks[signature]

            if (isSignatureResponding) {
                if (log) console.log('function id :', funcId, ' Rejected')
                return
            }

            this.signatureLocks[signature] = true
            await trycode()
            delete this.signatureLocks[signature]
            if (log) console.log('function funcId :', funcId, ' Success')
        } catch (e: any) {
            await catchcode(e)
            this.isResponding = false
            delete this.signatureLocks[signature]
            if (log) console.log('function funcId :', funcId, ' crashed')
        }
    }

    async attempt({ trycode, catchcode, funcId = undefined }: IAttmept) {
        try {
            trycode()
        } catch (e: any) {
            await catchcode(e)
        }
    }

    async abortableRequest({ trycode, catchcode, funcId = undefined, log = false }: IAbortable) {
        try {
            this.requestController.abort()
            this.requestController = new AbortController()
            await trycode(this.requestController)
        } catch (error: any) {
            await catchcode(error)
            if (log) console.log('function funcId :', funcId, ' crashed')
        }
    }
}

import { Frame } from '@/lib/SFrame'

export const styles = {
    parent: new Frame<{ focus: boolean }>().shared(({ focus }) => ({
        fontFamily: 'dms-medium',
        padding: '0px 16px',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderColor: focus ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.08)',
        background: 'rgba(255, 255, 255, 0.04)',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        height: '40px',
    })),
}

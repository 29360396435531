import { Frame } from '@/lib/SFrame'
import { isSafariBrowser } from '@/lib/SFrame'

export const styles = {
    parent: new Frame<{ isTicketQRCodeFullscreen: boolean }>()
        .shared({
            width: '100%',
            height: '64px',
            position: 'fixed',
            top: '0px',
            left: '0px',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'border-box',
        })
        .includes(['widescreen', 'desktop'], ({ isTicketQRCodeFullscreen }) => ({
            background: isTicketQRCodeFullscreen || isSafariBrowser() ? 'rgba(18, 18, 18, 1)' : 'rgba(18, 18, 18, 0.6)',
            backdropFilter: isSafariBrowser() ? undefined : 'blur(30px)',
            padding: '0px 66px',
            boxSizing: 'border-box',
        }))
        .mobile({
            background: 'rgba(18, 18, 18, 1)',
            padding: '0px 16px',
        }),
    menu: {
        parentFrame: new Frame().shared({
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '80px',
            zIndex: 3,
        }),
        bg: new Frame().shared({
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(60px)',
            zIndex: 3,
            backgroundColor: 'rgba(18, 18, 18, 1)',
        }),
        item: new Frame().shared({
            fontSize: '37px',
            cursor: 'pointer',
        }),
    },
}

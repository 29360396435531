import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLoggedIn, routing, authStore, logout } from '@/app'
import { AnimatePresence, motion } from 'framer-motion'
import { Text, Image } from '@/shared/styleComponents'
import { styles } from './NavBarMobile.styles'
import LogoImg from '@/assets/logo.png'
import './NavBarMobile.css'
import { ticketStore } from '@/pages/customer/ticket/Ticket'

export const MobileNavBar = () => {
    const [showMenu, setShowMenu] = useState(false)
    const nav = useNavigate()
    const fullscreenQRCode = ticketStore.use.fullscreenQRCode()
    const { isLoggedIn } = useLoggedIn()
    const authSet = authStore.use.set()

    useEffect(() => {
        // Disable scroll when menu is open
        document.body.style.overflow = showMenu ? 'hidden' : ''
    }, [showMenu])

    return (
        <>
            <div
                className="navBar-mobile"
                style={styles.parent.provide({ isTicketQRCodeFullscreen: fullscreenQRCode })}
            >
                <Link to={routing.landingRoute.route.navigate()}>
                    <Image src={LogoImg} overrides={{ width: '128px', height: '32px' }} />
                </Link>

                {isLoggedIn && (
                    <div
                        className={`hamburger-lines ${showMenu ? 'enabled' : ''}`}
                        onClick={() => {
                            setShowMenu(!showMenu)
                        }}
                    >
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
                )}
            </div>
            <AnimatePresence>
                {showMenu && isLoggedIn && (
                    <>
                        <motion.div
                            className="with-backdrop"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}
                            style={styles.menu.bg.provide()}
                        />
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { delay: 0.3, duration: 1 } }}
                            exit={{ opacity: 0 }}
                            style={styles.menu.parentFrame.provide()}
                            onClick={() => {
                                setShowMenu(false)
                            }}
                        >
                            <Text
                                overrides={styles.menu.item.provide()}
                                onClick={() => nav(routing.myTicketsRoute.route.navigate())}
                            >
                                My Tickets
                            </Text>
                            {isLoggedIn ? <Text
                                overrides={styles.menu.item.provide()}
                                onClick={() => {
                                    logout()
                                }}
                            >
                                Sign out
                            </Text> : ''}
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </>
    )
}

import { InferCSSFromFrames } from '@/lib/SFrame'
import React from 'react'
import { styles } from './Input.styles'

type Props = {
    type: 'text'
    placeholder?: string
    value?: string
    onChange?: (i: string) => void
    overrides?: InferCSSFromFrames<typeof styles>
}
export const Input: React.FC<Props> = ({ type, value, placeholder, onChange, overrides }) => {
    return (
        <input
            type={type}
            value={value}
            placeholder={placeholder ?? 'Enter text here...'}
            style={styles.parent.provide(null, overrides?.parent)}
        />
    )
}

import { colors } from '@/colors'
import { fonts } from '@/fonts'
import { Frame } from '@/lib/SFrame'

export const styles = {
    parent: new Frame().shared({
        border: '2px solid #FFFFFF',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 15px',
        height: '50px',
        width: 'fit-content',
        minWidth: '132px',
    }),
    text: new Frame().shared({
        fontSize: '17px',
        color: colors['var(--white)'],
        fontFamily: fonts.dms['dms-medium'],
    }),
}

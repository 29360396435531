import { styles } from './Landing.styles'
import { HeroCard } from './components'
import { Image } from '@/shared/styleComponents'
import { Text } from '@/shared/styleComponents'
import placeholderIMG from '@/assets/placeholders.png'
import eventBackgroundIMG from '@/assets/eventbackground.png'
import { Stat } from './components/stats'
import { PrimaryButton } from '@/shared/components/'
import { colors } from '@/colors'
import { useLoggedIn, useLoginUser } from '@/app'
import { useGetEventQuery, graphqlRequestClient } from '@/graphql/'
import { useNavigate } from 'react-router-dom'
import { routing, settings } from '@/app/'
import './Landing.css'
import { safeParseReleapServerError } from '@/utils/error/error'
import { toastStore } from '@/shared/components/toast/Toaster'
import { useEffect } from 'react'
import { Skeletonizer } from '@/shared/components/'

export const Landing = () => {
    const toastSet = toastStore.use.set()
    const { login, isError: isErrorLogin, isLoading: isLoadingLogin } = useLoginUser()
    const { isLoggedIn } = useLoggedIn()
    const {
        data: event,
        isLoading,
        isError,
    } = useGetEventQuery(
        graphqlRequestClient,
        { id: settings.event.id },
        {
            select: (data) => data.event,
            onError(err) {
                const cause = safeParseReleapServerError(err)
                console.log(err)
                // if (!cause) {
                //     toastSet({ type: 'error', message: 'Oops something went wrong!' })
                //     return
                // }
                // toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
            },
        },
    )

    const nav = useNavigate()
    return (
        <div className="landing" style={styles.parent.provide()}>
            <Image src={eventBackgroundIMG} overrides={styles.backgroundImage.provide()} />
            <div className="hero" style={styles.hero.parent.provide()}>
                <HeroCard title={event?.name} />
                <div className="body" style={styles.hero.body.parent.provide()}>
                    <div className="title" style={styles.hero.body.title.parent.provide()}>
                        <Skeletonizer
                            waitFor={event?.name}
                            loadingStyle={styles.hero.body.title.mainText.provide(null, { width: '100%' })}
                        >
                            <Text overrides={styles.hero.body.title.mainText.provide()}>{event?.name}</Text>
                        </Skeletonizer>

                        <Skeletonizer
                            waitFor={event?.description}
                            loadingStyle={styles.hero.body.title.subText.provide(null, {
                                width: '100%',
                            })}
                        >
                            <Text className="line-clamp" overrides={styles.hero.body.title.subText.provide()}>
                                {event?.description}
                            </Text>
                        </Skeletonizer>
                    </div>
                    <div className="stats" style={styles.hero.body.stats.parent.provide()}>
                        {/* TODO: Show fixed ticket count for now */}
                        <Stat text={event?.totalTicketCount ? `800 Tickets` : undefined} />
                        <Stat text={event?.price ? `$${event?.price} HKD` : undefined} />
                    </div>
                    <Skeletonizer
                            waitFor={event?.name}
                            loadingStyle={styles.hero.body.title.subText.provide(null, {
                                height: '56px',
                                width: '100%',
                                borderRadius: '12px',
                                maxWidth: '545px',
                                padding: '0px 16px'
                            })}
                        >
                    {isLoggedIn ? (
                        <PrimaryButton
                            text="Buy Tickets"
                            overrides={{ parent: styles.hero.body.loggedInButton.parent?.provide() }}
                            animation={{ whileHover: { background: 'rgba(255, 232, 114, 0.9)' } }}
                            onClick={() => nav(routing.paymentRoute.route.navigate())}
                        />
                    ) : (
                        <PrimaryButton
                            text="Sign up"
                            isLoading={isLoadingLogin}
                            onClick={() => login()}
                            overrides={{ parent: styles.hero.body.notLoggedInButton.parent?.provide() }}
                        />
                    )}
                    </Skeletonizer>
                </div>
            </div>
        </div>
    )
}

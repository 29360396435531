import { colors } from '@/colors'
import { fonts } from '@/fonts'
import { Frame, isSafariBrowser, InferFrames } from '@/lib/SFrame'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame<{ isTicketQRCodeFullscreen: boolean }>()
        .shared({
            width: '100%',
            height: '64px',
            position: 'fixed',
            top: '0px',
            left: '0px',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            boxSizing: 'border-box',
        })
        .includes(['widescreen', 'desktop'], ({ isTicketQRCodeFullscreen }) => ({
            background: isTicketQRCodeFullscreen || isSafariBrowser() ? 'rgba(18, 18, 18, 1)' : 'rgba(18, 18, 18, 0.6)',
            backdropFilter: isSafariBrowser() ? undefined : 'blur(30px)',
            padding: '0px 66px',
            boxSizing: 'border-box',
        }))
        .mobile({
            background: 'rgba(18, 18, 18, 1)',
            padding: '0px 16px',
        }),
    body: {
        parent: new Frame()
            .shared({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            })
            .desktop({
                maxWidth: '1160px',
            }),
        controls: {
            parent: new Frame().shared({
                display: 'flex',
                alignItems: 'center',
                gap: '40px',
            }),
            button: {
                parent: new Frame()
                    .shared({
                        height: '40px',
                        maxWidth: '140px',
                        background: colors['var(--primary)'],
                        borderRadius: '6px',
                    })
                    .whileHover({
                        background: 'rgba(78, 68, 206, 0.8)',
                    }),
                text: new Frame().shared({
                    color: colors['var(--white)'],
                    fontSize: '16px',
                    fontFamily: fonts.dms['dms-regular'],
                }),
            } as InferFrames<typeof PrimaryButtonStyles>,
        },
    },
}

import { QueryClient, MutationCache } from '@tanstack/react-query'

//define global callbacks for react query side effects

export const reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30,
            retry: 0,
        },
        mutations: {
            cacheTime: 30,
        },
    },
})

import { Frame, InferFrames } from '@/lib/SFrame'
import { colors } from '@/colors'
import { fonts } from '@/fonts'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame()
        .shared({
            background: 'var(--background)',
            minHeight: '100vh',
            paddingTop: '144px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
        }),
    backgroundImage: new Frame().shared({
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        objectFit: 'cover',
    }),
    body: {
        parent: new Frame().shared({
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }),
        title: new Frame()
            .shared({
                fontFamily: fonts.dms['dms-medium'],
                color: colors['var(--white)'],
            })
            .includes(['widescreen', 'desktop'], {
                fontSize: '78px',
            })
            .mobile({
                fontSize: '50px',
            }),
        inputs: {
            parent: new Frame().shared({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: '40px',
                margin: '30px 0px 55px 0px',
            }),
            input: {
                parent: new Frame().shared({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    width: '100%',
                    maxWidth: '356px',
                }),
            },
        },
        button: {
            parent: new Frame()
                .shared({
                    height: '40px',
                    maxWidth: '152px',
                    width: '100%',
                    background: colors['var(--primary)'],
                    borderRadius: '6px',
                })
                .whileHover({
                    background: 'rgba(78, 68, 206, 0.8)',
                }),
            text: new Frame().shared({
                color: colors['var(--white)'],
                fontSize: '16px',
                fontFamily: fonts.dms['dms-regular'],
            }),
        } as InferFrames<typeof PrimaryButtonStyles>,
    },
}

import { motion } from 'framer-motion'
import { Animatable } from '@/lib/SFrame'
import React, { CSSProperties } from 'react'
import { styles } from './Text.styles'
import _ from 'lodash'
import { Colors } from '@/colors'

export function EMFromString(pixels: string, appendUnit = true, base = 16) {
    return appendUnit ? `${parseFloat(pixels) / base}em` : parseFloat(pixels) / base
}

const parseOverrides = (overrides: CSSProperties) => {
    const size = overrides?.fontSize ?? '16px'
    const fontSizeParsed = EMFromString(size.toString())
    return { ...overrides, fontSize: fontSizeParsed }
}

type FontFamilies = {
    ppo: 'ppo-heavy' | 'ppo-bold' | 'ppo-medium' | 'ppo-regular' | 'ppo-light' | 'ppo-thin'
    dms: 'dms-bold' | 'dms-medium' | 'dms-regular'
    dmm: 'dmm-regular'
    work: 'work-extra-bold' | 'work-bold' | 'work-medium' | 'work-regular' | 'work-light' | 'work-thin'
}

export type Props = {
    className?: string
    onClick?: React.MouseEventHandler
    children?: React.ReactNode
    fontFamily?: FontFamilies[keyof FontFamilies]
    fontSize?: number
    lineHeight?: number
    color?: CSSProperties['color'] | Colors
    textAlign?: CSSProperties['textAlign']
    animation?: Animatable
    overrides?: CSSProperties
}
export const Text: React.FC<Props> = ({
    className,
    fontFamily,
    fontSize,
    lineHeight,
    color,
    textAlign,
    animation,
    overrides,
    onClick,
    children,
}) => {
    const _fontSize = fontSize ? EMFromString(fontSize.toString()) : undefined
    const _overrides = overrides ? parseOverrides(overrides) : {}
    const _incomingStyles = _.omitBy(
        { fontSize: _fontSize, lineHeight, fontFamily, color, textAlign, ..._overrides },
        _.isUndefined,
    )

    return (
        <motion.div
            className={className ?? 'text'}
            {...(animation as any)}
            onClick={onClick}
            style={styles.default.provide(null, _incomingStyles)}
        >
            {children}
        </motion.div>
    )
}

import { QueryClientProvider } from '@tanstack/react-query'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { PrivateRoute, useLoginOnRefresh } from './auth'
import { routes } from './routing'
import { reactQueryClient } from './query'
import { useMediaQuery, BreakpointGaurd } from '@/lib/SFrame'
import { Navbar } from '@/shared/components/'
import { Toast } from '@/shared/components/toast/Toaster'

const AppContent = () => {
    useLoginOnRefresh()
    return (
        <div className="App">
            <Navbar />
            <Toast />
            <Routes>
                {routes.map((route, idx) => {
                    const children = route.page()
                    const wrappedChildren = route.private ? <PrivateRoute children={children} /> : children
                    return <Route path={route.route.path} element={wrappedChildren} key={idx} />
                })}
            </Routes>
        </div>
    )
}

export const App = () => {
    useMediaQuery()
    return (
        <QueryClientProvider client={reactQueryClient}>
            <BrowserRouter>
                <BreakpointGaurd>
                    <AppContent />
                </BreakpointGaurd>
            </BrowserRouter>
        </QueryClientProvider>
    )
}

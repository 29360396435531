export const SuccessIcon = () => {
    return (
        <svg width="86" height="86" viewBox="0 0 86 86" fill="none">
            <rect width="86" height="86" rx="43" fill="#00D18C" />
            <path
                d="M35.6236 60.3126L20.6736 45.0587C19.7755 44.1423 19.7755 42.6564 20.6736 41.7399L23.9262 38.4211C24.8244 37.5046 26.2808 37.5046 27.1789 38.4211L37.25 48.6967L58.8211 26.6873C59.7192 25.7709 61.1756 25.7709 62.0738 26.6873L65.3264 30.0061C66.2245 30.9226 66.2245 32.4084 65.3264 33.325L38.8763 60.3127C37.9781 61.2291 36.5218 61.2291 35.6236 60.3126Z"
                fill="white"
            />
        </svg>
    )
}

export const ErrorIcon = () => {
    return (
        <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="86" height="86" rx="43" fill="#F91880" />
            <path d="M53.8494 59.1436L59.1379 53.8516L32.1556 26.8515L26.8671 32.1435L53.8494 59.1436Z" fill="white" />
            <path d="M32.1566 59.1504L59.139 32.1504L53.8504 26.8584L26.868 53.8584L32.1566 59.1504Z" fill="white" />
        </svg>
    )
}

import { Frame, InferFrames } from '@/lib/SFrame'
import { colors } from '@/colors'
import { fonts } from '@/fonts'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    backgroundImage: new Frame().shared({
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        objectFit: 'cover',
    }),
    body: {
        parent: new Frame().shared({
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '50px',
        }),
        qrScanner: {
            parent: new Frame<{ isIdle: boolean }>().shared(({ isIdle }) => ({
                background: 'rgba(255, 255, 255, 0.06)',
                borderRadius: '22px',
                padding: '21px 21px 17px',
                height: isIdle ? undefined : '291px',
                width: isIdle ? undefined : '382px',
                display: isIdle ? undefined : 'flex',
                alignItems: isIdle ? undefined : 'center',
                justifyContent: isIdle ? undefined : 'center',
            })),
            aim: new Frame<{ qrDetected: boolean }>().shared(({ qrDetected }) => ({
                position: 'absolute',
                left: '50%',
                top: '48%',
                transform: 'translate(-50%, -50%)',
                height: '177px',
                width: '240px',
                border: qrDetected ? '4px solid rgba(0, 209, 140, 0.4)' : '4px solid rgba(249, 24, 128, 0.4)',
                borderRadius: '6px',
            })),
            scanner: new Frame().shared({
                width: 'auto',
                height: '287px',
                borderRadius: '14px',
            }),
        },
        status: {
            icon: new Frame().shared({
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '40px',
                width: '380px',
            }),
        },
        button: {
            parent: new Frame()
                .shared({
                    height: '40px',
                    maxWidth: '152px',
                    background: colors['var(--white)'],
                    borderRadius: '6px',
                })
                .whileHover({
                    background: 'rgba(255, 255, 255, 0.8)',
                }),
            text: new Frame().shared({
                color: colors['var(--black)'],
                fontSize: '16px',
                fontFamily: fonts.dms['dms-medium'],
            }),
        } as InferFrames<typeof PrimaryButtonStyles>,
    },
}

import { InferCSSFromFrames } from '@/lib/SFrame'
import { CSSProperties, FC } from 'react'
import { SpinnerCircularFixed } from 'spinners-react'
import { Colors } from '@/colors'

export interface SpinnerProps {
    size: number
    color: CSSProperties['color'] | Colors
    secondaryColor?: CSSProperties['color'] | Colors
}

export const Spinner: FC<SpinnerProps> = ({ size, color, secondaryColor }) => {
    return (
        <div>
            <SpinnerCircularFixed
                size={size}
                thickness={180}
                speed={100}
                color={color}
                secondaryColor={secondaryColor ?? 'none'}
            />
        </div>
    )
}

import create, { StoreApi, UseBoundStore } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import _ from 'lodash'
import { configurePersist } from 'zustand-persist'

// types
export type Actions<S> = (set: StoreApi<S>['setState'], get: StoreApi<S>['getState'], api: StoreApi<S>) => S

interface Options {
    log: {}
    persist: Parameters<typeof persist>['1']
}

// Store Creation and setup
export const createStore = <Store extends unknown>(
    actions: Actions<Store>,
    storeName?: string,
    peristOptions?: PersistOptions<Store, Store>,
) => {
    const useStore = peristOptions ? create<Store>()(persist(actions, peristOptions)) : create<Store>()(actions)
    if (import.meta.env.DEV && storeName) {
        mountStoreDevtool(storeName, useStore)
    }
    return createSelectors(useStore)
}

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never

export const createSelectors = <S extends UseBoundStore<StoreApi<any>>>(_store: S) => {
    let store = _store as WithSelectors<typeof _store>
    store.use = {}
    for (let k of Object.keys(store.getState())) {
        ;(store.use as any)[k] = () => store((s) => s[k as keyof typeof s])
    }

    return store
}

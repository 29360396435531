import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { createStore, mutator, Mutator, UpdateMutator } from '@/lib/zust'
import _ from 'lodash'
import Torus from '@toruslabs/solana-embed'
import {
    useLoginChallengeMutation,
    useSubmitLoginChallengeMutation,
    graphqlRequestClient,
    useGetArtistQuery,
    Artist,
} from '@/graphql/'
import { useCallback } from 'react'
import bs58 from 'bs58'
import { RaceGate } from '@/lib/racegate'
import { toastStore } from '@/shared/components/toast/Toaster'
import { safeParseReleapServerError } from '@/utils/error/error'
import { cachedNFTByOwner, clearNFTByOwner } from '@/lib/Cache/cache'

export let torus = new Torus()

export const torusLogin = async () => {
    if (!torus.isInitialized) {
        await torus.init({
            buildEnv: 'production', // uses solana-testing.tor.us (which uses testnet)
            enableLogging: false, // default : false
            showTorusButton: false,
            useLocalStorage: false, // default: true
        })
    }
    const publicKeys = await torus.login()
    return publicKeys[0]
}

export class TorusProxy {
    torus: Torus
    constructor() {
        this.torus = new Torus()
    }
}

export interface Store {
    user: Artist | null
    accessToken: string
    autoLoggedInAt: number
    operatorSecret: string
    set: Mutator<Store, Partial<Store>>
}

export const authStore = createStore<Store>(
    (set, get) => ({
        user: null,
        accessToken: '',
        autoLoggedInAt: Date.now(),
        operatorSecret: '',
        set: mutator(set, (draft, payload) => {
            draft = Object.assign(draft, payload)
        }),
    }),
    'auth-store',
    { name: 'releap-ticketing-auth-store' },
)

export const logout = async () => {
    // logout
    await torus.logout()
    clearNFTByOwner;
    authStore.setState({ accessToken: '', user: null, operatorSecret: '' })
}

const loginOnRefreshGate = new RaceGate()
export const useLoginOnRefresh = () => {
    const authSet = authStore.use.set()
    const access = authStore.use.accessToken()
    const user = authStore.use.user()
    const toastSet = toastStore.use.set()

    useEffect(() => {
        const autoLogin = async () => {
            loginOnRefreshGate.lock({
                trycode: async () => {
                    await torusLogin()
                    authSet({ autoLoggedInAt: Date.now() })
                    console.log('use', torus.isLoggedIn)
                },
                catchcode: (err) => {
                    const cause = safeParseReleapServerError(err)
                    // if (!cause) {
                    //     toastSet({ type: 'error', message: 'Oops something went wrong!' })
                    //     return
                    // }
                    // toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
                },
            })
        }

        if (access && user) {
            autoLogin()
        }
    }, [])
}

const loginRaceGate = new RaceGate()
export const useLoginUser = () => {
    // client state
    const authSet = authStore.use.set()

    const [publicKey, setPubKey] = useState('')
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isSuccessLogin, setIsSuccessLogin] = useState(false)
    const toastSet = toastStore.use.set()

    // server state
    const { mutateAsync: loginChallenge } = useLoginChallengeMutation(graphqlRequestClient, {})
    const { mutateAsync: submitLoginChallenge } = useSubmitLoginChallengeMutation(graphqlRequestClient)

    useGetArtistQuery(
        graphqlRequestClient,
        { publicKey },
        {
            enabled: isSuccessLogin,
            onSuccess(data) {
                authSet({ user: data.artist })
                setIsLoading(false)
                setIsSuccess(true)
            },
            onError(err) {
                setIsError(true)
                setIsLoading(false)

                const cause = safeParseReleapServerError(err)
                if (!cause) {
                    toastSet({ type: 'error', message: 'Oops something went wrong!' })
                    return
                }
                toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
            },
        },
    )

    const login = useCallback(() => {
        loginRaceGate.lock({
            trycode: async () => {
                setIsLoading(true)
                const publicKey = await torusLogin()
                const loginChallengeRes = await loginChallenge({ publicKey })
                const jwt = loginChallengeRes.requestLoginChallenge?.jwt
                const signedData = loginChallengeRes.requestLoginChallenge?.signData
                if (!jwt || !signedData) throw new Error()
                const encodedSignData = new TextEncoder().encode(signedData)
                const signedMessage = await torus.signMessage(encodedSignData)
                const submitLoginChallengeRes = await submitLoginChallenge({
                    jwt,
                    signature: bs58.encode(signedMessage),
                })
                const accessToken = submitLoginChallengeRes.submitLoginChallenge

                if (accessToken) {
                    authSet({ accessToken })
                }
                setIsSuccessLogin(true)
                setPubKey(publicKey)
            },
            catchcode: (err) => {
                if (err.code === -32603) {
                    setIsLoading(false)
                    return
                }
                setIsError(true)
                setIsLoading(false)

                const cause = safeParseReleapServerError(err)
                if (!cause) {
                    toastSet({ type: 'error', message: 'Oops something went wrong!' })
                    return
                }
                toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
            },
        })
    }, [])

    return {
        login,
        isError,
        isLoading,
        isSuccess,
    }
}

export const useLoggedIn = () => {
    const accessToken = authStore.use.accessToken()
    const user = authStore.use.user()

    // subscribe to changes of autoLoggedInAt variable -> auto refresh function can force re-render when torus gets updated
    authStore.use.autoLoggedInAt()

    return {
        accessToken,
        user,
        isLoggedIn: (accessToken ? true : false) && (user ? true : false) && torus.isLoggedIn,
    }
}

interface PrivateRouteProps {
    children: React.ReactNode
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const loc = useLocation()
    const { isLoggedIn } = useLoggedIn()

    if (isLoggedIn) {
        return <React.Fragment>{children}</React.Fragment>
    }

    if (loc.pathname === '/' || loc.pathname === '*') {
        throw new Error(
            'A Private route with the path / or * is trying to route back to itself at path / or *. This will cause an infinite loop',
        )
    }

    return loc.pathname === '/' ? <Navigate to="*" replace /> : <Navigate to="/" replace />
}

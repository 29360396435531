import { styles } from './MyTickets.styles'
import { Image, Text } from '@/shared/styleComponents'
import myTicketsBackground from '@/assets/my-ticket-background.png'
import { PrimaryButton, Skeletonizer } from '@/shared/components/'
import { colors } from '@/colors'
import { motion } from 'framer-motion'
import { useGetUserTickets } from '@/shared/hooks/'
import { useNavigate } from 'react-router-dom'
import { TicketCard } from './components'
import { useGetBreakpoint } from '@/lib/SFrame'
import { isDesktopDevice } from '@/lib/SFrame/hooks/tools'

export const MyTickets = () => {
    const { tickets, eventImage, isLoading, isError, error } = useGetUserTickets({ clearCache: true })

    return (
        <div className="my-tickets" style={styles.parent.provide()}>
            {isDesktopDevice() ? <Image src={myTicketsBackground} overrides={styles.backgroundImage.provide()} /> : ''}
            <div className="body" style={styles.body.parent.provide()}>
                <Text overrides={styles.body.title.provide()}>My Tickets</Text>
                {tickets?.length === 0 ? <Text overrides={styles.body.text.provide()}>You have no tickets</Text> : <div
                    className="grid"
                    style={styles.body.grid.parent.provide({ onlyOne: tickets?.length === 1 || tickets === null })}
                >
                    {tickets?.map((ticket, idx) => {
                        return (
                            <TicketCard
                                title={ticket.metadata.data.name}
                                img={eventImage}
                                mint={ticket.metadata.mint}
                                key={idx}
                            />
                        )
                    })}
                    <Skeletonizer loading={isLoading} repeat={1} LoadingView={TicketCard} />
                </div>}
            </div>
        </div>
    )
}

import { Frame, InferFrames } from '@/lib/SFrame'
import { fonts } from '@/fonts'
import { colors } from '@/colors'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame()
        .shared({
            background: 'var(--background)',
            minHeight: '100vh',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
        })
        .includes(['widescreen', 'desktop'], {
            paddingTop: '64px',
            alignItems: 'center',
        })
        .mobile({
            padding: '86px 0px 0px',
        }),
    backgroundImage: new Frame().shared({
        height: '100vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        objectFit: 'cover',
    }),
    hero: {
        parent: new Frame()
            .shared({
                display: 'flex',
                position: 'relative',
                zIndex: 2,
                width: '100%',
                alignItems: 'center',
            })
            .includes(['widescreen', 'desktop'], {
                gap: '50px',
                justifyContent: 'center',
            })
            .mobile({
                flexDirection: 'column',
                gap: '15px',
                padding: '0px 16px 40px',
                boxSizing: 'border-box',
            }),
    },
}

export const optionStyles = {
    parent: new Frame()
        .shared({
            display: 'flex',
            flexDirection: 'column',
        })
        .includes(['desktop', 'widescreen'], {
            gap: '29px',
            maxWidth: '572px',
            margin: '17px 0px 38px',
        })
        .mobile({
            width: '100%',
            maxWidth: '364px',
            alignItems: 'center',
        }),
    title: new Frame()
        .shared({
            fontFamily: fonts.ppo['ppo-medium'],
            color: colors['var(--white)'],
        })
        .includes(['desktop', 'widescreen'], {
            fontSize: '50px',
        })
        .mobile({
            fontSize: '28px',
        }),
    options: {
        parent: new Frame()
            .shared({
                display: 'flex',
                flexDirection: 'column',
            })
            .mobile({
                margin: '10px 0px 10px',
            }),
        option: {
            parent: new Frame()
                .shared({
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                })
                .includes(['widescreen', 'desktop'], {
                    gap: '26px',
                    height: '108px',
                })
                .mobile({
                    gap: '15px',
                    height: '70px',
                }),
            indicator: {
                parent: new Frame()
                    .shared({
                        height: '16px',
                        width: '16px',
                        border: `2px solid ${colors['var(--white)']}`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                    })
                    .whileTap({
                        scale: 0.97,
                    }),
                inner: new Frame<{ isSelected: boolean; isHovering: boolean }>()
                    .shared({
                        height: '10px',
                        width: '10px',
                        borderRadius: '50%',
                    })
                    .animate(({ isSelected, isHovering }) => ({
                        background: isSelected ? colors['var(--secondary)'] : 'rgba(255, 255, 255, 0.3)',
                        opacity: isSelected || isHovering ? 1 : 0,
                    })),
            },
            text: new Frame()
                .shared({
                    fontFamily: fonts.ppo['ppo-medium'],
                    color: colors['var(--white)'],
                })
                .includes(['widescreen', 'desktop'], {
                    fontSize: '25px',
                })
                .mobile({
                    fontSize: '18px',
                }),
        },
    },
    button: {
        parent: new Frame()
            .shared({
                background: colors['var(--secondary)'],
            })
            .mobile({
                width: '100%',
                maxWidth: '455px',
            })
            .whileHover({
                background: 'rgba(255, 232, 114, 0.9)',
            }),
    } as InferFrames<typeof PrimaryButtonStyles>,
}

export const successStyles = {
    parent: new Frame()
        .shared({
            display: 'flex',
            flexDirection: 'column',
        })
        .includes(['desktop', 'widescreen'], {
            gap: '29px',
            maxWidth: '572px',
            margin: '17px 0px 38px',
        })
        .mobile({
            width: '100%',
            alignItems: 'center'
        }),
    text: {
        parent: new Frame()
            .shared({
                display: 'flex',
                flexDirection: 'column',
            })
            .includes(['widescreen', 'desktop'], {
                gap: '43px',
            })
            .mobile({
                gap: '15px',
            }),
        title: new Frame()
            .shared({
                fontFamily: fonts.ppo['ppo-medium'],
                color: colors['var(--white)'],
            })
            .includes(['desktop', 'widescreen'], {
                fontSize: '60px',
            })
            .mobile({
                fontSize: '25px',
                textAlign: 'center',
            }),
        subtitle: new Frame()
            .shared({
                fontFamily: fonts.ppo['ppo-regular'],
                color: colors['var(--white)'],
            })
            .includes(['desktop', 'widescreen'], {
                fontSize: '25px',
                lineHeight: '50px',
            })
            .mobile({
                fontSize: '20px',
                textAlign: 'center'
            }),
    },
    button: {
        parent: new Frame()
            .shared({
                background: colors['var(--white)'],
            })
            .mobile({
                width: '100%',
                maxWidth: '455px',
                marginTop: '40px',
            })
            .whileHover({
                background: 'rgba(255, 255, 255, 0.85)',
            }),
    } as InferFrames<typeof PrimaryButtonStyles>,
}

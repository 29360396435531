type Settings = {
    event: {
        id: string
    }
}
export const settings = {
    event: {
        id: 'yayoi-kusama-1945-to-now',
    },
}

import { useEffect } from 'react'
import { Image } from '@/shared/styleComponents'
import { styles } from './QRCard.styles'
import placeholderIMG from '@/assets/placeholders.png'
import { Text } from '@/shared/styleComponents'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { simulateProcessingTime } from '@/utils/misc'
import { Skeletonizer } from '../skeletonizer/Skeletonizer'

type Props = {
    qrString: string
    caption?: string
    displayQRCodeOnly?: boolean
    ticketUsed?: boolean
    onClick?: React.MouseEventHandler
}
export const QRCard: React.FC<Props> = ({ qrString, caption, displayQRCodeOnly, onClick, ticketUsed }) => {
    const [loadingQR, setLoadingQR] = useState(displayQRCodeOnly ? false : true)

    useEffect(() => {
        const fetch = async () => {
            // QR Code needs a little will to load but there is no way to detect qrcode processing has finished
            await simulateProcessingTime({ seconds: 1 })
            setLoadingQR(false)
        }
        fetch()
    }, [])

    return (
        <div
            className="qr-card"
            style={styles.parent.provide({ displayQRCodeOnly, ticketUsed })}
            onClick={(e) => {
                if (qrString == "") return
                onClick?.(e)
            }
        }
        >
            <Skeletonizer loading={loadingQR} loadingStyle={styles.image.provide()}>
                {ticketUsed ? (
                    <div style={styles.tickedUsed.parent.provide()}>
                        <Text overrides={styles.tickedUsed.text.provide()}>Ticket has been used</Text>
                    </div>
                ) : (
                    <QRCodeSVG
                        value={qrString}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        level={'L'}
                        includeMargin={true}
                        style={styles.image.provide()}
                    />
                )}
            </Skeletonizer>
            {!displayQRCodeOnly && !ticketUsed && (
                <Text overrides={styles.text.provide()}>{caption ?? 'Tap here then show code on entry'}</Text>
            )}
        </div>
    )
}

import { InferCSSFromFrames, Animatable } from '@/lib/SFrame'
import { motion, AnimatePresence } from 'framer-motion'
import { Text } from '@/shared/styleComponents'
import { styles } from './PrimaryButton.styles'
import { Spinner } from '../../../styleComponents/spinner/Spinner'

export type Props = {
    text: string
    overrides?: InferCSSFromFrames<typeof styles>
    animation?: Animatable
    onClick?: React.MouseEventHandler
    disabled?: boolean
    isLoading?: boolean
}
export const PrimaryButton: React.FC<Props> = ({ text, onClick, overrides, animation, isLoading, disabled }) => {
    const _animation = disabled ? undefined : styles.parent.provideAnimation(null, animation)
    return (
        <motion.div
            className="primary-button"
            style={styles.parent.provide(null, {
                ...overrides?.parent,
                opacity: disabled ? 0.6 : 1,
                cursor: disabled ? 'default' : 'pointer',
            })}
            onClick={(e) => !disabled && onClick?.(e)}
            {..._animation}
        >
            {isLoading ? (
                <Spinner size={30} color="var(--black)" />
            ) : (
                <Text overrides={styles.text.provide(null, overrides?.text)}>{text}</Text>
            )}
        </motion.div>
    )
}

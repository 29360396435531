import { fonts } from '@/fonts'
import { colors } from '@/colors'
import { Frame, isSafariBrowser } from '@/lib/SFrame'

export const styles = {
    parent: new Frame<{ displayQRCodeOnly: boolean; ticketUsed: boolean }>()
        .shared(({ displayQRCodeOnly, ticketUsed }) => ({
            border: displayQRCodeOnly ? undefined : '1px solid rgba(255, 255, 255, 0.08)',
            cursor: displayQRCodeOnly || ticketUsed ? 'default' : 'pointer',
        }))
        .includes(['widescreen', 'desktop'], ({ displayQRCodeOnly }) => ({
            width: '557px',
            height: '600px',
            borderRadius: '20px',
            background: displayQRCodeOnly ? undefined : isSafariBrowser() ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.6)',
            backdropFilter: isSafariBrowser() ? undefined : 'blur(30px)',
            padding: '24px 24px 28px',
            boxSizing: 'border-box',
        }))
        .mobile(({ displayQRCodeOnly }) => ({
            background: displayQRCodeOnly ? undefined : 'rgba(18, 18, 18, 1)',
            padding: '16px',
            maxWidth: '400px',
            height: '377px',
            borderRadius: '20px',
            backdropFilter: isSafariBrowser() ? undefined : 'blur(30px)',
            boxSizing: 'border-box',
        })),
    image: new Frame()
        .includes(['desktop', 'widescreen'], {
            height: '512px',
            width: '100%',
        })
        .mobile({
            height: '310px',
            width: '302px',
        }),
    tickedUsed: {
        parent: new Frame()
            .shared({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            })
            .includes(['widescreen', 'desktop'], {
                height: '100%',
                width: '100%',
            })
            .mobile({
                height: '100%',
                width: '302px',
            }),
        text: new Frame()
            .shared({
                fontFamily: fonts.dms['dms-medium'],
            })
            .includes(['widescreen', 'desktop'], {
                fontSize: '30px',
            })
            .mobile({
                fontSize: '25px',
            }),
    },
    text: new Frame()
        .shared({
            fontFamily: fonts.dms['dms-bold'],
            color: colors['var(--white)'],
            textAlign: 'center',
        })
        .includes(['widescreen', 'desktop'], {
            fontSize: '23px',
            margin: '10px 0px 0px 0px',
        })
        .mobile({
            fontSize: '16px',
            margin: '10px 0px 0px 0px',
        }),
}

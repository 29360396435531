import produce, { Draft } from 'immer'
import { StoreApi } from 'zustand'
import _ from 'lodash'

// Helper Functions
export type Mutator<Store, Args> = ReturnType<MutatorGenerator<Store, Args>>
export type MutatorFunction<S, T> = (draft: Draft<S>, payload: T) => void

export type MutatorGenerator<S extends unknown, T> = (
    set: (partial: S | Partial<S> | ((state: S) => S | Partial<S>), replace?: boolean | undefined) => void,
    fn: (state: Draft<S>, input: T) => void,
) => (input: T) => void

export type UpdateMutator<State> = Mutator<
    State,
    (current: Readonly<Draft<State>>) =>
        | {
              merge: 'deep' | 'shallow'
              payload: Partial<State>
          }
        | undefined
>

export const mutator = <S extends unknown, T>(
    set: StoreApi<S>['setState'],
    fn: MutatorFunction<S, T>,
    debounce?: number,
) => {
    if (!debounce || debounce <= 0) {
        return (actionPayload: T) => {
            return set((incomingState) => {
                return produce<S>(incomingState, (incomingDraft) => {
                    fn(incomingDraft, actionPayload)
                })
            })
        }
    }
    return _.debounce((actionPayload: T) => {
        return set((incomingState) => {
            return produce<S>(incomingState, (incomingDraft) => {
                fn(incomingDraft, actionPayload)
            })
        })
    }, debounce)
}

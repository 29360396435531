import _ from "lodash";

export const shallowEqual = (
  objA: Record<string, any> | undefined,
  objB: Record<string, any> | undefined
): boolean => {
  if (!objA || !objB) {
    return false;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  if (objA === objB) {
    return true;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    const currentKey = keysA[i];
    if (!_.has(objB, currentKey) || objA[currentKey] !== objB[currentKey]) {
      return false;
    }
  }
  return true;
};

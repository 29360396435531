import React, { CSSProperties, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Frame } from '@/lib/SFrame'

export interface SkeletonProps {
    frame?: Frame<any>
    style?: CSSProperties
    randomWidth?: string[]
    disableShim?: boolean
}

export interface SkeletonizerProps {
    children?: any
    waitFor?: any
    loading?: boolean
    LoadingView?: any
    loadingViewDivider?: boolean
    frame?: Frame<any>
    repeat?: number
    parentStyle?: CSSProperties
    loadingStyle?: CSSProperties
    randomWidth?: string[]
    image?: boolean
    className?: string
    debug?: boolean
    disableShim?: boolean
}

export const Skeletonizer = React.memo(
    ({
        children,
        waitFor,
        loading,
        LoadingView,
        loadingViewDivider,
        repeat,
        frame,
        parentStyle,
        loadingStyle,
        randomWidth,
        image,
        className,
        debug,
        disableShim,
    }: SkeletonizerProps) => {
        const [state, setState] = useState({ ready: waitFor != null && image == null, oldWaitFor: waitFor })
        if (debug) {
            console.debug(`ready: ${state.ready}`)
        }
        useEffect(() => {
            // restart the cycle, eg: profile image URL updated

            if (waitFor !== state.oldWaitFor) {
                if (debug) {
                    console.debug('waitFor changed')
                }
                setState((old) => ({ ...old, oldWaitFor: waitFor, ready: waitFor != null && image == null }))
            }
        }, [waitFor, state.oldWaitFor, image, debug])

        useEffect(() => {
            if (loading) {
                setState((old) => ({ ...old, ready: !loading }))
            }
        }, [loading, debug])

        useEffect(() => {
            if (debug) {
                console.debug(`ready changed: ${state.ready}`)
            }
        }, [state.ready, debug])

        useEffect(() => {
            let imageElement: HTMLImageElement | null = null
            const imgListener = () => {
                if (debug) {
                    console.log(`loaded image ${state.oldWaitFor}`)
                }
                setState((old) => ({ ...old, ready: true }))
            }

            if (state.ready && loading == null) {
                return
            }
            if ((waitFor != null || loading === false) && !image) {
                setState((old) => ({ ...old, ready: true }))
            } else if (image && state.oldWaitFor != null) {
                if (debug) {
                    console.log(`loading image ${state.oldWaitFor}`)
                }
                // preload the image in the background and keep the skeleon
                imageElement = new Image()
                imageElement.addEventListener('load', imgListener)
                imageElement.src = state.oldWaitFor
            }
            return () => {
                imageElement?.removeEventListener('load', imgListener)
            }
        }, [waitFor, children, image, state.ready, state.oldWaitFor, loading, debug])

        if (state.ready) {
            return children ?? null
        } else {
            const inner = new Array(repeat ?? 1).fill(1).map((_, idx, arr) => {
                return LoadingView == null ? (
                    <Skeleton
                        key={`skeleton_${idx}`}
                        frame={frame}
                        style={loadingStyle}
                        randomWidth={randomWidth}
                        disableShim={disableShim}
                    />
                ) : (
                    <div key={idx}>
                        <LoadingView style={loadingStyle} />
                        {loadingViewDivider && idx < arr.length - 1 && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '1px',

                                    margin: '20px 0',
                                    backgroundColor: 'rgba(255, 255, 255, 0.25)',
                                }}
                            />
                        )}
                    </div>
                )
            })

            return parentStyle || className ? (
                <div style={parentStyle} className={className}>
                    {inner}
                </div>
            ) : (
                inner
            )
        }
    },
)

export const Skeleton = ({ frame, style, randomWidth, disableShim }: SkeletonProps) => {
    const frameToUse = frame ?? new Frame()
    const computedStyle = frameToUse.provideSkeleton({}, style)

    if (randomWidth) {
        const random = Math.floor(Math.random() * randomWidth.length)
        computedStyle.width = randomWidth[random]
    }

    return (
        <div style={computedStyle}>
            {!disableShim && (
                <motion.div
                    style={{
                        position: 'relative',
                        width: '50%',
                        height: '100%',
                        background: 'rgba(255, 255, 255, 0.05)',
                        boxShadow: '0 0 30px 30px rgba(255, 255, 255, 0.05)',
                        skewX: '-20deg',
                        transformOrigin: 'bottom left',
                    }}
                    animate={{
                        // Add some buffer to make sure the shimmer goes outside the skeleon
                        translateX: ['calc(-250% - 30px)', 'calc(200% + 30px)'],
                    }}
                    transition={{
                        duration: 1.5,
                        repeat: Infinity,
                    }}
                />
            )}
        </div>
    )
}

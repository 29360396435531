export type FontFamilies = {
    ppo: 'ppo-heavy' | 'ppo-bold' | 'ppo-medium' | 'ppo-regular' | 'ppo-light' | 'ppo-thin'
    dms: 'dms-bold' | 'dms-medium' | 'dms-regular'
    dmm: 'dmm-regular'
    work: 'work-extra-bold' | 'work-bold' | 'work-medium' | 'work-regular' | 'work-light' | 'work-thin'
}

type FontMap = {
    [K in keyof FontFamilies]: { [F in FontFamilies[K]]: F }
}
export const fonts: FontMap = {
    ppo: {
        'ppo-bold': 'ppo-bold',
        'ppo-heavy': 'ppo-heavy',
        'ppo-light': 'ppo-light',
        'ppo-medium': 'ppo-medium',
        'ppo-regular': 'ppo-regular',
        'ppo-thin': 'ppo-thin',
    },
    dmm: {
        'dmm-regular': 'dmm-regular',
    },
    dms: {
        'dms-bold': 'dms-bold',
        'dms-medium': 'dms-medium',
        'dms-regular': 'dms-regular',
    },
    work: {
        'work-bold': 'work-bold',
        'work-extra-bold': 'work-extra-bold',
        'work-light': 'work-light',
        'work-medium': 'work-medium',
        'work-regular': 'work-regular',
        'work-thin': 'work-thin',
    },
}

import { toast, ToastContainer } from 'react-toastify'
import { createStore, mutator, Mutator } from '@/lib/zust'
import _ from 'lodash'
import { styles } from './Toaster.styles'
import 'react-toastify/dist/ReactToastify.css'

export interface Store {
    set: Mutator<Store, { type: 'info' | 'error'; message: string }>
}

export const toastStore = createStore<Store>(
    (set, get) => ({
        set: mutator(set, (draft, payload) => {
            if (payload.type === 'info') {
                toast.info(payload.message)
            }

            if (payload.type === 'error') {
                toast.error(payload.message, { style: styles.parent.provide() })
            }
        }),
    }),
    'toast-store',
)
const NAV_HEIGHT = 64
export const Toast = () => {
    return (
        <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={true}
            closeOnClick
            style={{ marginTop: NAV_HEIGHT, zIndex: 400000 }}
            theme="dark"
            limit={1}
        />
    )
}

import React, { useState } from 'react'
import { styles } from './AdminLanding.styles'
import adminBackground from '@/assets/admin-background.png'
import { Image } from '@/shared/styleComponents'
import { Text } from '@/shared/styleComponents'
import { Input } from '@/shared/components/'
import { PrimaryButton } from '@/shared/components/'
import { authStore } from '@/app'
import { useNavigate } from 'react-router-dom'
import { routing } from '@/app'

export const AdminLanding = () => {
    const authSet = authStore.use.set()
    const nav = useNavigate()
    const [password, setPassword] = useState('test-operator-secret')

    return (
        <div className="admin-landing" style={styles.parent.provide()}>
            <Image src={adminBackground} overrides={styles.backgroundImage.provide()} />
            <div className="body" style={styles.body.parent.provide()}>
                <Text fontSize={40} fontFamily="dms-medium" color="var(--white)">
                    Releap Ticketing
                </Text>
                <div className="inputs" style={styles.body.inputs.parent.provide()}>
                    <div className="email" style={styles.body.inputs.input.parent.provide()}>
                        <Text>Operator Password</Text>
                        <Input
                            type="text"
                            value={password}
                            placeholder="Enter password here..."
                            onChange={(input) => setPassword(input)}
                        />
                    </div>
                </div>
                <PrimaryButton
                    text="Sign in"
                    overrides={{
                        parent: styles.body.button?.parent?.provide(),
                        text: styles.body.button.text?.provide(),
                    }}
                    animation={styles.body.button.parent?.provideAnimation()}
                    onClick={() => {
                        authSet({ operatorSecret: password })
                        nav(routing.verifyRoute.route.navigate())
                    }}
                />
            </div>
        </div>
    )
}

import { Frame, InferFrames } from '@/lib/SFrame'
import { fonts } from '@/fonts'
import { colors } from '@/colors'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame()
        .shared({
            background: 'rgb(14,14,14)',
            minHeight: '100vh',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
        })
        .includes(['widescreen', 'desktop'], () => ({
            padding: '125px 0px 61px 0px',
        }))
        .mobile({
            padding: '85px 0px 61px 0px',
        }),
    backgroundImage: new Frame().shared({
        height: '100vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        objectFit: 'cover',
    }),
    body: {
        parent: new Frame()
            .shared({
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 2,
                gap: '48px',
            })
            .mobile({
                maxWidth: '360px',
            }),
        title: new Frame().shared({
            fontFamily: fonts.ppo['ppo-medium'],
            color: colors['var(--white)'],
            textAlign: 'center',
        }).includes(['widescreen', 'desktop'], ({ }) => ({
            fontSize: '48px',
        })).mobile({
            fontSize: '30px',
        }),
        text: new Frame().shared({
            fontFamily: fonts.ppo['ppo-medium'],
            color: 'rgba(255, 255, 255, 0.7)',
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '30px'
        }),
        grid: {
            parent: new Frame<{ onlyOne: boolean }>()
                .shared({
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    zIndex: 1,
                    display: 'grid',
                    gap: '20px',
                })
                .includes(['widescreen', 'desktop'], ({ onlyOne }) => ({
                    gridTemplateColumns: onlyOne ? '1fr' : '1fr 1fr 1fr 1fr',
                    width: onlyOne ? '100%' : '1160px',
                    maxWidth: onlyOne ? '300px' : undefined,
                }))
                .mobile({
                    gridTemplateColumns: '1fr',
                    width: '100%',
                    maxWidth: '500px',
                }),
        },
    },
}

import { getNFTByOwner } from '@releap/js-sdk'

export type FnAsync<Args extends any[], R> = (...args: Args) => Promise<R>
export type FnSync<Args extends any[], R> = (...args: Args) => R
export type KeyExtractor<Args extends any[]> = (...args: Args) => string

type OrRejected<T> = T | 'rejected'

function save<T>(storageKey: string, map: Record<string, T>) {
    sessionStorage.setItem(storageKey, JSON.stringify(map))
}

function load<T>(storageKey: string): Record<string, T> {
    return JSON.parse(sessionStorage.getItem(storageKey) ?? '{}')
}

function clear(storageKey: string) {
    sessionStorage.removeItem(storageKey)
}

/**
 * Use carefully if caching user specific response, like user's NFT list
 * Should use the clear handler to purge the cahge when user changing the wallet
 * @example
 * const [cachedNFTMetadata, clearCachedNFTData] = cacheAsync(fetchNFT, (_, mint) => mint.toBase58())
 * const nft = await cachedNFTMetadata(connection, tokenMint)
 **/
export function cacheAsync<Args extends any[], R>(
    cacheFn: FnAsync<Args, R>,
    options?: {
        keyExtractor?: KeyExtractor<Args>
        cacheError?: boolean
        persist?: boolean
        cacheName?: string
    },
): [FnAsync<Args, R>, () => void] {
    let cache: Record<string, OrRejected<R>> = options?.persist ? load(cacheFn.name) : {}

    const cacheName = options?.cacheName ?? Date.now().toString()

    return [
        async function cached(...args: Args) {
            const key = options?.keyExtractor ? options?.keyExtractor(...args) : JSON.stringify(args)
            if (cache[key]) {
                const value = cache[key]
                if (value === 'rejected') {
                    throw value
                }
                return value
            }
            try {
                const value = await cacheFn(...args)
                cache[key] = value
                options?.persist && save(cacheName, cache)
                return value
            } catch (err) {
                if (options?.cacheError) {
                    cache[key] = 'rejected'
                }
                throw err
            }
        },
        () => {
            cache = {}
            clear(cacheFn.name)
        },
    ]
}

export function cacheSync<Args extends any[], R>(
    cacheFn: FnSync<Args, R>,
    options?: {
        keyExtractor?: KeyExtractor<Args>
        cacheError?: boolean
    },
): [FnSync<Args, R>, () => void] {
    const cache = new Map()
    return [
        function cached(...args: Args) {
            const key = options?.keyExtractor ? options?.keyExtractor(...args) : JSON.stringify(args)
            if (cache.has(key)) {
                const value = cache.get(key)
                if (value instanceof Error) {
                    throw value
                }
                return cache.get(key)
            }
            try {
                const value = cacheFn(...args)
                cache.set(key, value)
                return value
            } catch (err) {
                if (options?.cacheError) {
                    cache.set(key, err)
                }
                throw err
            }
        },
        () => cache.clear(),
    ]
}

// global singleton cache
const [cachedNFTByOwner, clearNFTByOwner] = cacheAsync(getNFTByOwner, {
    keyExtractor: (_, publickey) => publickey.toBase58(),
    persist: true,
    cacheName: 'getNFTByOwner',
})

export { cachedNFTByOwner, clearNFTByOwner }

import { Frame, InferFrames } from '@/lib/SFrame'
import { fonts } from '@/fonts'
import { colors } from '@/colors'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame<{ fullscreenQRCode: boolean }>()
        .shared(({ fullscreenQRCode }) => ({
            background: fullscreenQRCode ? colors['var(--white)'] : colors['var(--background)'],
            minHeight: '100vh',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: fullscreenQRCode ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }))
        .includes(['widescreen', 'desktop'], {
            paddingTop: '64px',
        })
        .mobile(({ fullscreenQRCode }) => ({
            padding: fullscreenQRCode ? '82px 16px 16px' : '86px 16px 16px',
        })),
    backgroundImage: new Frame().shared({
        height: '100vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        objectFit: 'cover',
    }),
    hero: {
        parent: new Frame()
            .shared({
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                zIndex: 2,
                width: '100%',
                justifyContent: 'center',
            })
            .includes(['widescreen', 'desktop'], {
                gap: '50px',
            })
            .mobile({
                flexDirection: 'column-reverse',
                gap: '30px',
                padding: '0px 0px 40px',
                boxSizing: 'border-box',
            }),
        body: {
            parent: new Frame()
                .shared({
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                })
                .includes(['desktop', 'widescreen'], {
                    gap: '29px',
                    maxWidth: '545px',
                })
                .mobile({
                    maxWidth: '336px',
                }),
            title: {
                parent: new Frame().shared({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }),
                mainText: new Frame()
                    .shared({
                        fontFamily: fonts.ppo['ppo-medium'],
                        color: colors['var(--white)'],
                    })
                    .includes(['desktop', 'widescreen'], {
                        fontSize: '48px',
                    })
                    .mobile({
                        fontSize: '30px',
                        textAlign: 'center',
                    }),
                description: {
                    parent: new Frame()
                        .shared({
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        })
                        .mobile({
                            textAlign: 'center',
                        }),
                    bullet: {
                        key: new Frame()
                            .shared({
                                fontFamily: fonts.dms['dms-bold'],
                                color: colors['var(--white)'],
                                marginRight: '5px',
                            })
                            .includes(['widescreen', 'desktop'], {
                                fontSize: '28px',
                            })
                            .mobile({
                                fontSize: '20px',
                            }),
                        value: new Frame().shared({
                            fontFamily: fonts.dms['dms-regular'],
                            color: colors['var(--white)'],
                            fontSize: '20px',
                        }),
                    },
                },
            },
        },
        bodyTapped: {
            button: {
                parent: new Frame()
                    .shared({
                        height: '48px',
                        width: '188px',
                        maxWidth: undefined,
                        borderRadius: '8px',
                        background: colors['var(--black)'],
                        marginTop: '20px',
                    })
                    .whileHover({
                        background: 'rgba(0, 0, 0, 0.85)',
                    }),
                text: new Frame().shared({
                    color: colors['var(--white)'],
                    fontSize: '16px',
                    fontFamily: fonts.dms['dms-bold'],
                }),
            } as InferFrames<typeof PrimaryButtonStyles>,
        },
    },
}

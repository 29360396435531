import { GenericObject } from '@/shared/types/utils'
import _ from 'lodash'

type Code =
    | 'TICKETING_ERROR_INVALID_TICKET_TOKEN'
    | 'TICKETING_ERROR_TICKET_USED'
    | 'SYSTEM_DATABASE_ERROR'
    | 'SYSTEM_NOT_IMPLEMENTED_ERROR'
    | 'SYSTEM_INTERNAL_SERVER_ERROR'
    | 'MARKETPLACE_NFT_NOT_WHITELISTED'
    | 'INPUT_FILE_SIZE_EXCECCED_LIMIT'
    | 'INPUT_FILE_TYPE_NOT_SUPPORTED'
    | 'INPUT_FIELD_MISSING'
    | 'INPUT_INVALID'
    | 'GENERIC_STORE_NOT_FOUND'
    | 'GENERIC_NOT_FOUND'
    | 'BLOCKCHAIN_FAIL_TO_CONFIRM_TX'
    | 'BLOCKCHAIN_ARWEAVE_UPLOAD_FAIL'
    | 'BLOCKCHAIN_NFT_NOT_BURNED'
    | 'AUTH_VERIFICATION_FAIL'
    | 'AUTH_UNAUTHORIZED'
    | 'AUTH_TOKEN_INVALID'
    | 'AUTH_TOKEN_EXPIRED'
    | 'AUTH_YOU_ARE_NOT_CIRCLER'
    | 'TICKETING_ERROR_INVALID_TICKET_TOKEN'
    | 'TICKETING_ERROR_TICKET_USED'

const codes = new Set<Code>([
    'TICKETING_ERROR_INVALID_TICKET_TOKEN',
    'TICKETING_ERROR_TICKET_USED',
    'SYSTEM_DATABASE_ERROR',
    'SYSTEM_NOT_IMPLEMENTED_ERROR',
    'SYSTEM_INTERNAL_SERVER_ERROR',
    'MARKETPLACE_NFT_NOT_WHITELISTED',
    'INPUT_FILE_SIZE_EXCECCED_LIMIT',
    'INPUT_FILE_TYPE_NOT_SUPPORTED',
    'INPUT_FIELD_MISSING',
    'INPUT_INVALID',
    'GENERIC_STORE_NOT_FOUND',
    'GENERIC_NOT_FOUND',
    'BLOCKCHAIN_FAIL_TO_CONFIRM_TX',
    'BLOCKCHAIN_ARWEAVE_UPLOAD_FAIL',
    'BLOCKCHAIN_NFT_NOT_BURNED',
    'AUTH_VERIFICATION_FAIL',
    'AUTH_UNAUTHORIZED',
    'AUTH_TOKEN_INVALID',
    'AUTH_TOKEN_EXPIRED',
    'AUTH_YOU_ARE_NOT_CIRCLER',
    'TICKETING_ERROR_INVALID_TICKET_TOKEN',
    'TICKETING_ERROR_TICKET_USED',
])

const validReleapErrorPaths = {
    responseLoc: 'response.errors[0]',
    extensionLogRefLoc: 'response.errors[0].extensions.logRef',
    extensionCodeLoc: 'response.errors[0].extensions.code',
    messageLoc: 'response.errors[0].message',
}

type ReleapServerError = {
    code: Code
    logRef?: string
    message?: string
}

export const safeParseReleapServerError = (error: unknown): ReleapServerError | undefined => {
    const releapErrorCode = _.get(error, validReleapErrorPaths.extensionCodeLoc)
    const releapErrorLogRef = _.get(error, validReleapErrorPaths.extensionLogRefLoc)
    const releapErrorMessage = _.get(error, validReleapErrorPaths.messageLoc)

    if (!codes.has(releapErrorCode)) return

    return {
        code: releapErrorCode as Code,
        logRef: releapErrorLogRef,
        message: releapErrorMessage,
    }
}

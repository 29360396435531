import { Image, Text } from '@/shared/styleComponents'
import { styles } from './HeroCard.styles'
import placeholderIMG from '@/assets/placeholders.png'
import { Skeletonizer } from '../skeletonizer/Skeletonizer'
import { useGetBreakpoint } from '@/lib/SFrame'

type Props = {
    title?: string
    image?: string
}
export const HeroCard: React.FC<Props> = ({ title, image }) => {
    const breakpoint = useGetBreakpoint()
    return (
        <div className="hero-card" style={styles.parent.provide()}>
            <Skeletonizer waitFor={placeholderIMG} loadingStyle={styles.image.provide()} image>
                <Image src={placeholderIMG} overrides={styles.image.provide()} />
            </Skeletonizer>
            <Skeletonizer
                waitFor={title}
                loadingStyle={styles.text.provide(null, {
                    width: '100%',
                    height: breakpoint === 'mobile' ? '24px' : '37px',
                })}
            >
                <Text overrides={styles.text.provide()}>{title}</Text>
            </Skeletonizer>
        </div>
    )
}

import { motion } from 'framer-motion'
import { Image, Text } from '@/shared/styleComponents'
import { PrimaryButton, Skeletonizer } from '@/shared/components/'
import { styles } from './TicketCard.styles'
import { routing } from '@/app'
import { useNavigate } from 'react-router-dom'

type Props = {
    img?: string
    title?: string
    mint?: string
}
export const TicketCard: React.FC<Props> = ({ img, title, mint }) => {
    const nav = useNavigate()
    return (
        <motion.div
            className="ticket-card"
            style={styles.parent.provide()}
            onClick={() => mint && nav(routing.ticketRoute.route.navigate({ mint }))}
        >
            <Skeletonizer waitFor={img} loadingStyle={styles.image.provide()} image>
                <Image src={img} overrides={styles.image.provide()} />
            </Skeletonizer>
            <Skeletonizer waitFor={title} loadingStyle={styles.title.provide(null, { width: '100%', height: '36px' })}>
                <Text overrides={styles.title.provide()}>{title}</Text>
            </Skeletonizer>

            <PrimaryButton
                disabled={mint ? false : true}
                text="View Ticket"
                overrides={{
                    parent: styles.button.parent?.provide(),
                    text: styles.button.text?.provide(),
                }}
                animation={styles.button.parent?.provideAnimation()}
                onClick={() => mint && nav(routing.ticketRoute.route.navigate({ mint }))}
            />
        </motion.div>
    )
}

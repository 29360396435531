import { Frame, InferFrames } from '@/lib/SFrame'
import { colors } from '@/colors'
import { fonts } from '@/fonts'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame().shared({
        background: 'var(--background)',
        minHeight: '100vh',
        height: '100vh',
        paddingTop: '64px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    scanner: new Frame()
        .includes(['widescreen', 'desktop'], {
            width: '382px',
            height: '287px',
            objectFit: 'cover',
            borderRadius: '14px',
        })
        .mobile({
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }),
}

import React from 'react'
import { styles, optionStyles, successStyles } from './Payment.styles'
import { HeroCard } from '@/shared/components/'
import { Image } from '@/shared/styleComponents'
import { Text } from '@/shared/styleComponents'
import placeholderIMG from '@/assets/placeholders.png'
import eventBackgroundIMG from '@/assets/eventbackground.png'
import { PrimaryButton } from '@/shared/components/'
import { colors } from '@/colors'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { routing, settings } from '@/app'
import { useGetEventQuery, graphqlRequestClient, useRequestTicketMutation } from '@/graphql/'
import fpsColorIMG from '../../../assets/fps_color.svg'
import octopusCardIMG from '../../../assets/octopus_card.png'
import creditCardIMG from '../../../assets/credit_cards.png'

type PaymentOptions = 'payment0' | 'payment1' | 'payment2'
type Props = {
    onConfirm: (selected: PaymentOptions) => void
}
const OptionSelectLayout: React.FC<Props> = ({ onConfirm }) => {
    const [selected, setSelected] = useState<PaymentOptions | null>(null)
    const [hovered, setHovered] = useState<PaymentOptions | null>(null)
    const { mutateAsync: requestTicket, isLoading } = useRequestTicketMutation(graphqlRequestClient)
    return (
        <div className="body" style={optionStyles.parent.provide()}>
            <Text overrides={optionStyles.title.provide()}>Payment Options</Text>

            <div className="payment options" style={optionStyles.options.parent.provide()}>
                <div
                    className="option"
                    style={optionStyles.options.option.parent.provide()}
                    onClick={() => setSelected('payment0')}
                    onMouseEnter={() => setHovered('payment0')}
                    onMouseLeave={() => setHovered(null)}
                >
                    <motion.div
                        className="indicator"
                        style={optionStyles.options.option.indicator.parent.provide()}
                        {...optionStyles.options.option.indicator.parent.provideAnimation()}
                    >
                        <motion.div
                            className="inner-ring"
                            style={optionStyles.options.option.indicator.inner.provide()}
                            {...optionStyles.options.option.indicator.inner.provideAnimation({
                                isSelected: selected === 'payment0',
                                isHovering: hovered === 'payment0',
                            })}
                        />
                    </motion.div>
                    <Text overrides={optionStyles.options.option.text.provide()}>FPS / 轉數快</Text>
                    <img src={fpsColorIMG} style={{ height: '55px' }} />
                </div>
                <div
                    className="option"
                    style={optionStyles.options.option.parent.provide()}
                    onClick={() => setSelected('payment1')}
                    onMouseEnter={() => setHovered('payment1')}
                    onMouseLeave={() => setHovered(null)}
                >
                    <motion.div
                        className="indicator"
                        style={optionStyles.options.option.indicator.parent.provide()}
                        {...optionStyles.options.option.indicator.parent.provideAnimation()}
                    >
                        <motion.div
                            className="inner-ring"
                            style={optionStyles.options.option.indicator.inner.provide()}
                            {...optionStyles.options.option.indicator.inner.provideAnimation({
                                isSelected: selected === 'payment1',
                                isHovering: hovered === 'payment1',
                            })}
                        />
                    </motion.div>
                    <Text overrides={optionStyles.options.option.text.provide()}>Octopus</Text>
                    <img src={octopusCardIMG} style={{ height: '45px' }} />
                </div>
                <div
                    className="option"
                    style={optionStyles.options.option.parent.provide()}
                    onClick={() => setSelected('payment2')}
                    onMouseEnter={() => setHovered('payment2')}
                    onMouseLeave={() => setHovered(null)}
                >
                    <motion.div
                        className="indicator"
                        style={optionStyles.options.option.indicator.parent.provide()}
                        {...optionStyles.options.option.indicator.parent.provideAnimation()}
                    >
                        <motion.div
                            className="inner-ring"
                            style={optionStyles.options.option.indicator.inner.provide()}
                            {...optionStyles.options.option.indicator.inner.provideAnimation({
                                isSelected: selected === 'payment2',
                                isHovering: hovered === 'payment2',
                            })}
                        />
                    </motion.div>
                    <Text overrides={optionStyles.options.option.text.provide()}>Card Payment</Text>
                    <img src={creditCardIMG} style={{ height: '50px' }} />
                </div>
            </div>

            <PrimaryButton
                isLoading={isLoading}
                text="Confirm"
                onClick={async () => {
                    if (selected && onConfirm != null) {
                        await requestTicket({ eventId: settings.event.id })
                        onConfirm(selected)
                    }
                }}
                overrides={{ parent: optionStyles.button.parent?.provide() }}
                animation={optionStyles.button.parent?.provideAnimation()}
            />
        </div>
    )
}

const PaymentSuccessLayout = () => {
    const nav = useNavigate()
    return (
        <div className="body" style={successStyles.parent.provide()}>
            <div className="text" style={successStyles.text.parent.provide()}>
                <Text overrides={successStyles.text.title.provide()}>Your payment has been confirmed!</Text>
                <Text overrides={successStyles.text.subtitle.provide()}>
                    Your ticket will be visible in your account shortly!
                </Text>
            </div>
            <PrimaryButton
                text="My Tickets"
                onClick={() => nav(routing.myTicketsRoute.route.navigate())}
                overrides={{ parent: successStyles.button.parent?.provide() }}
                animation={successStyles.button.parent?.provideAnimation()}
            />
        </div>
    )
}

const paymentSuccess = true
export const Payment = () => {
    const [success, setSuccess] = useState(false)
    const {
        data: event,
        isLoading,
        isError,
    } = useGetEventQuery(graphqlRequestClient, { id: settings.event.id }, { select: (data) => data.event })
    return (
        <div className="payment" style={styles.parent.provide()}>
            <Image src={eventBackgroundIMG} overrides={styles.backgroundImage.provide()} />
            <div className="hero" style={styles.hero.parent.provide()}>
                <HeroCard title={event?.name ?? ''} />
                {success ? (
                    <PaymentSuccessLayout />
                ) : (
                    <OptionSelectLayout onConfirm={(payementOption) => setSuccess(true)} />
                )}
            </div>
        </div>
    )
}

import { Text } from '@/shared/styleComponents'
import { styles } from './Stat.styles'
import { InferCSSFromFrames } from '@/lib/SFrame'
import { Skeletonizer } from '@/shared/components/'

export type Props = {
    text?: string
    overrides?: InferCSSFromFrames<typeof styles>
}
export const Stat: React.FC<Props> = ({ text, overrides }) => {
    return (
        <Skeletonizer
            waitFor={text}
            loadingStyle={styles.parent.provide(null, {
                ...overrides?.parent,
                border: 'none',
                minWidth: '156px',
                height: '54px',
            })}
        >
            <div className="stat" style={styles.parent.provide(null, overrides?.parent)}>
                <Text overrides={styles.text.provide(null, overrides?.text)}>{text}</Text>
            </div>
        </Skeletonizer>
    )
}

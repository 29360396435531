import { fonts } from '@/fonts'
import { colors } from '@/colors'
import { Frame, isSafariBrowser } from '@/lib/SFrame'

export const styles = {
    parent: new Frame()
        .shared({
            border: '1px solid rgba(255, 255, 255, 0.08)',
        })
        .includes(['widescreen', 'desktop'], {
            width: '560px',
            height: '628px',
            borderRadius: '20px',
            background: isSafariBrowser() ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.6)',
            backdropFilter: isSafariBrowser() ? undefined : 'blur(30px)',
            padding: '24px 24px 28px',
            boxSizing: 'border-box',
        })
        .mobile({
            background: 'rgba(18, 18, 18, 1)',
            padding: '16px',
            width: '100%',
            maxWidth: '364px',
            height: '400px',
            borderRadius: '20px',
            backdropFilter: isSafariBrowser() ? undefined : 'blur(30px)',
            boxSizing: 'border-box',
        }),
    image: new Frame()
        .includes(['desktop', 'widescreen'], {
            height: '512px',
            width: '100%',
        })
        .mobile({
            height: '330px',
            width: '100%',
        }),
    text: new Frame()
        .shared({
            fontFamily: fonts.dms['dms-medium'],
            color: colors['var(--white)'],
        })
        .includes(['widescreen', 'desktop'], {
            fontSize: '28px',
            margin: '19px 0px 0px 0px',
        })
        .mobile({
            fontSize: '20px',
            margin: '9px 0px 0px 0px',
        }),
}

import React, { useState } from 'react'
import { styles } from './Ticket.styles'
import { QRCard, Skeletonizer } from '@/shared/components/'
import { Image, Text } from '@/shared/styleComponents'
import eventBackgroundIMG from '@/assets/eventbackground.png'
import {
    useGetEventQuery,
    useGetTicketNfTsQuery,
    useGetTicketAndEventQuery,
    useRequestAdmissionJwtMutation,
    graphqlRequestClient,
} from '@/graphql/'
import { useNavigate, useParams } from 'react-router-dom'
import { routing, settings } from '@/app/'
import { useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { RaceGate } from '@/lib/racegate'
import { PrimaryButton } from '@/shared/components/'
import _ from 'lodash'
import { Mutator, mutator, createStore } from '@/lib/zust'
import dayjs from 'dayjs'
import { useGetBreakpoint } from '@/lib/SFrame'

export interface Store {
    fullscreenQRCode: boolean
    set: Mutator<Store, Partial<Store>>
}

export const ticketStore = createStore<Store>(
    (set, get) => ({
        fullscreenQRCode: false,
        set: mutator(set, (draft, payload) => {
            draft = Object.assign(draft, payload)
        }),
    }),
    'ticket-page-store',
)

//#region data hook
const admissionGate = new RaceGate()
const useComponentData = () => {
    let { mint } = useParams()
    const [qrCode, setQRCode] = useState('')
    const [errorHook, setErrorHook] = useState<any>()

    const {
        data,
        isLoading: isLoadingTicket,
        isError: isErrorTicket,
        error: errorTicket,
    } = useGetTicketAndEventQuery(graphqlRequestClient, { mint: mint ?? '' })

    const {
        mutateAsync,
        isLoading: isLoadingQR,
        isError: isErrorQR,
        error: errorQR,
    } = useRequestAdmissionJwtMutation(graphqlRequestClient)

    useEffect(() => {
        const fetch = () => {
            admissionGate.lock({
                trycode: async () => {
                    if (!mint) return
                    const res = await mutateAsync({ mint: mint })
                    const jwt = res.requestAdmissionJWT
                    if (!jwt) return // TODO ERROR HANDLE HERE
                    setQRCode(jwt)
                },
                catchcode: (e) => {
                    setErrorHook(e)
                    console.log(e);
                },
            })
        }
        fetch()
    }, [mint])

    const event = data?.ticket?.event
    const nft = data?.ticket?.nft
    const ticket = {
        usedAt: data?.ticket?.usedAt,
    }
    return {
        event,
        ticket,
        nft,
        qrCode,
        isLoadingTicket,
        isErrorTicket,
        errorTicket,
        isLoadingQR,
        isErrorQR,
        errorQR,
        errorHook,
    }
}
//#endregion

export const Ticket = () => {
    const { event: event, ticket, nft, qrCode, errorHook } = useComponentData()
    const set = ticketStore.use.set()
    const fullscreenQRCode = ticketStore.use.fullscreenQRCode()

    const breakpoint = useGetBreakpoint()

    const ticketUsed = ticket.usedAt !== null
    return (
        <div className="ticket" style={styles.parent.provide({ fullscreenQRCode })}>
            {!fullscreenQRCode || ticketUsed ? (
                <React.Fragment>
                    <Image src={eventBackgroundIMG} overrides={styles.backgroundImage.provide()} />
                    <div className="hero" style={styles.hero.parent.provide()}>
                        <div className="body" style={styles.hero.body.parent.provide()}>
                            <div className="title" style={styles.hero.body.title.parent.provide()}>
                                <Skeletonizer
                                    waitFor={event?.name}
                                    loadingStyle={styles.hero.body.title.mainText.provide(null, {
                                        width: '100%',
                                        height: breakpoint === 'mobile' ? '60px' : undefined,
                                    })}
                                >
                                    <Text overrides={styles.hero.body.title.mainText.provide()}>{event?.name}</Text>
                                </Skeletonizer>
                                <div
                                    className="description"
                                    style={styles.hero.body.title.description.parent.provide()}
                                >
                                    <Skeletonizer
                                        waitFor={event?.name}
                                        loadingStyle={styles.hero.body.title.description.bullet.key.provide(null, {
                                            width: '40%',
                                            alignSelf: breakpoint === 'mobile' ? 'center' : undefined,
                                        })}
                                    >
                                        <Text overrides={styles.hero.body.title.description.bullet.value.provide()}>
                                            <b style={styles.hero.body.title.description.bullet.key.provide()}>
                                                Edition:
                                            </b>
                                            {`#${nft?.name?.split('#')[1]}`}
                                        </Text>
                                    </Skeletonizer>

                                    <Skeletonizer
                                        waitFor={event?.eventDate}
                                        loadingStyle={styles.hero.body.title.description.bullet.key.provide(null, {
                                            width: '70%',
                                            alignSelf: breakpoint === 'mobile' ? 'center' : undefined,
                                        })}
                                    >
                                        <Text overrides={styles.hero.body.title.description.bullet.value.provide()}>
                                            <b style={styles.hero.body.title.description.bullet.key.provide()}>Date:</b>{' '}
                                            {dayjs(event?.eventDate).format('dddd, MMMM D, YYYY h:mm A')}
                                        </Text>
                                    </Skeletonizer>

                                    <Skeletonizer
                                        waitFor={event?.location}
                                        loadingStyle={styles.hero.body.title.description.bullet.key.provide(null, {
                                            width: '80%',
                                            alignSelf: breakpoint === 'mobile' ? 'center' : undefined,
                                        })}
                                    >
                                        <Text overrides={styles.hero.body.title.description.bullet.value.provide()}>
                                            <b style={styles.hero.body.title.description.bullet.key.provide()}>
                                                Location:
                                            </b>
                                            {event?.location}
                                        </Text>
                                    </Skeletonizer>
                                </div>
                            </div>
                        </div>
                        <QRCard
                            qrString={qrCode}
                            onClick={() => !ticketUsed && set({ fullscreenQRCode: true })}
                            ticketUsed={errorHook ?? ticket.usedAt !== null}
                        />
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <QRCard qrString={qrCode} displayQRCodeOnly ticketUsed={ticket.usedAt !== null} />
                    <PrimaryButton
                        text="Close"
                        overrides={{
                            parent: styles.hero.bodyTapped.button.parent?.provide(),
                            text: styles.hero.bodyTapped.button.text?.provide(),
                        }}
                        animation={styles.hero.bodyTapped.button.parent?.provideAnimation()}
                        onClick={() => set({ fullscreenQRCode: false })}
                    />
                </React.Fragment>
            )}
        </div>
    )
}

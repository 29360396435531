import { Landing, Payment, MyTickets, Ticket, AdminLanding, Verify } from '@/pages'

export interface Route<Path extends string, PathParams = void> {
    route: {
        isRoute: (path: string) => boolean
        path: Path
        navigate: (params: PathParams) => string
    }
    private: boolean
    page: () => React.ReactNode
}

const landingRoutePath = '/'
export const landingRoute: Route<typeof landingRoutePath> = {
    route: {
        isRoute: (path) => path.includes('landing'),
        path: landingRoutePath,
        navigate(...pathParams) {
            return landingRoutePath
        },
    },
    private: false,
    page() {
        return <Landing />
    },
}

const paymentRoutePath = '/payment'
export const paymentRoute: Route<typeof paymentRoutePath> = {
    route: {
        isRoute: (path) => path.includes('payment'),
        path: paymentRoutePath,
        navigate(...pathParams) {
            return paymentRoutePath
        },
    },
    private: true,
    page() {
        return <Payment />
    },
}

const myTicketsRoutePath = '/myticket'
export const myTicketsRoute: Route<typeof myTicketsRoutePath> = {
    route: {
        isRoute: (path) => path.includes('myticket'),
        path: myTicketsRoutePath,
        navigate(...pathParams) {
            return myTicketsRoutePath
        },
    },
    private: true,
    page() {
        return <MyTickets />
    },
}

const ticketRoutePath = '/ticket/:mint'
export const ticketRoute: Route<typeof ticketRoutePath, { mint: string }> = {
    route: {
        isRoute: (path) => path.includes('ticket'),
        path: ticketRoutePath,
        navigate(params) {
            return `/ticket/${params.mint}`
        },
    },
    private: true,
    page() {
        return <Ticket />
    },
}

// admin routes
const adminRoutePath = '/admin'
export const adminRoute: Route<typeof adminRoutePath> = {
    route: {
        isRoute: (path) => path.includes('admin'),
        path: adminRoutePath,
        navigate(...pathParams) {
            return adminRoutePath
        },
    },
    private: false,
    page() {
        return <AdminLanding />
    },
}

const verifyRoutePath = '/verify'
export const verifyRoute: Route<typeof verifyRoutePath> = {
    route: {
        isRoute: (path) => path.includes('verify'),
        path: verifyRoutePath,
        navigate(...pathParams) {
            return verifyRoutePath
        },
    },
    private: false,
    page() {
        return <Verify />
    },
}

const pageNotFound404Path = '*'
export const pageNotFound404Route: Route<typeof pageNotFound404Path> = {
    route: {
        isRoute: (path) => path.includes('*'),
        path: pageNotFound404Path,
        navigate: () => pageNotFound404Path,
    },
    private: false,
    page: () => <h1>404 Page Not Found</h1>,
}

export const routes = [
    landingRoute,
    paymentRoute,
    myTicketsRoute,
    ticketRoute,
    adminRoute,
    verifyRoute,
    pageNotFound404Route,
]

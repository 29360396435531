import { styles } from './Verify.styles'
import { DesktopVerify } from './components/desktop/DesktopVerify'
import { MobileVerify } from './components/mobile/MobileVerify'
import { useGetBreakpoint } from '@/lib/SFrame'

import React, { useEffect, useMemo, useState } from 'react'
import { graphqlRequestClient, useVerifyAdmissionJwtMutation, Ticket } from '@/graphql/'
import { safeParseReleapServerError } from '@/utils/error/error'
import { toastStore } from '@/shared/components/toast/Toaster'
import QrReader from 'react-qr-scanner'
import { simulateProcessingTime } from '@/utils/misc'
import { authStore } from '@/app'
import { isDesktopDevice } from '../../../lib/SFrame/hooks/tools'

export type ComponentStatus =
    | 'samecode'
    | 'idle'
    | 'qrdetected'
    | 'samecode'
    | 'loading'
    | 'invalid'
    | 'verfied'
    | 'used'

export const Verify = () => {
    const breakpoint = useGetBreakpoint()
    const [prevQR, setPrevQR] = useState('')
    const [camDirection, setCamDirection] = useState('environment')
    const [verifiedTicket, setTicket] = useState<Omit<Ticket, 'event'> | null | undefined>(null)
    const [status, setStatus] = useState<ComponentStatus>('idle')
    const operatorSecret = authStore.use.operatorSecret()

    const toastSet = toastStore.use.set()

    const { mutate } = useVerifyAdmissionJwtMutation(graphqlRequestClient, {
        onMutate() {
            setStatus('loading')
        },
        onSuccess(data, variables, context) {
            setTicket(data.verifyAdmissionJWT)
            setStatus('verfied')
            setPrevQR(variables.jwt)
        },
        onError(error, variables, context) {
            setPrevQR(variables.jwt)
            const cause = safeParseReleapServerError(error)
            console.log(error)
            if (!cause) return

            if (cause.code === 'TICKETING_ERROR_INVALID_TICKET_TOKEN') {
                setStatus('invalid')
                return
            }

            if (cause.code === 'TICKETING_ERROR_TICKET_USED') {
                setStatus('used')
                return
            }
            setStatus('invalid')
            return;
        },
    })
    
    const qrScanner = useMemo(
        () => (
            <QrReader
                facingMode={camDirection}
                delay={1}
                onError={(e: any) => {
                    toastSet({
                        type: 'error',
                        message: 'Oops Webcam failure detected. Please refresh the page.',
                    })
                }}
                constraints={
                    isDesktopDevice()
                    ? undefined : {
                        video: {
                            facingMode: { exact: camDirection }
                        }
                      }
                }
                onScan={(res: any) => {
                    if (!res) return
                    if (!res?.text) return
                    if (prevQR === res.text) {
                        setStatus('samecode')
                        return
                    }
                    mutate({ jwt: res.text, operatorSecret })
                }}
                style={styles.scanner.provide()}
            />
        ),
        [breakpoint, prevQR, camDirection],
    )

    return (
        <div className="admin-landing" style={styles.parent.provide()}>
            {breakpoint === 'mobile' ? (
                <MobileVerify
                    status={status}
                    qrScanner={qrScanner}
                    onScanAnother={(e) => {
                        setStatus('idle')
                        setTicket(null)
                    }}
                    onSwitchCamera={() => 
                        setCamDirection(camDirection == 'environment' ? 'user' : 'environment')
                    }
                />
            ) : (
                <DesktopVerify
                    status={status}
                    qrScanner={qrScanner}
                    onScanAnother={(e) => {
                        setStatus('idle')
                        setTicket(null)
                    }}
                />
            )}
        </div>
    )
}

import { routing } from '@/app'
import { Image, Text } from '@/shared/styleComponents/'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { PrimaryButton } from '@/shared/components/'
import { styles } from './DektopNavbar.styles'
import { ticketStore } from '@/pages/customer/ticket/Ticket'
import LogoImg from '@/assets/logo.png'
import { useLoggedIn, logout } from '@/app'

export const DesktopNavbar = () => {
    const loc = useLocation()
    const nav = useNavigate()

    const fullscreenQRCode = ticketStore.use.fullscreenQRCode()
    const { isLoggedIn } = useLoggedIn()
    return (
        <div className="navbar" style={styles.parent.provide({ isTicketQRCodeFullscreen: fullscreenQRCode })}>
            <div className="body" style={styles.body.parent.provide()}>
                <Link
                    to={
                        loc.pathname === routing.verifyRoute.route.path
                            ? routing.adminRoute.route.navigate()
                            : routing.landingRoute.route.navigate()
                    }
                >
                    <Image src={LogoImg} overrides={{ width: '128px', height: '32px' }} />
                </Link>
                {isLoggedIn && (
                    <div className="controls" style={styles.body.controls.parent.provide()}>
                        <PrimaryButton
                            text="My Tickets"
                            overrides={{
                                parent: styles.body.controls.button?.parent?.provide(),
                                text: styles.body.controls.button.text?.provide(),
                            }}
                            animation={styles.body.controls.button.parent?.provideAnimation()}
                            onClick={() => nav(routing.myTicketsRoute.route.navigate())}
                        />
                        {isLoggedIn ? <Text
                            onClick={() => logout()}
                            overrides={{ cursor: 'pointer' }}
                            animation={{ whileHover: { color: 'rgba(255, 255, 255, 0.7)' } }}
                        >
                            Logout
                        </Text> : ''}
                    </div>
                )}
            </div>
        </div>
    )
}

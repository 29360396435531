import React from "react";
import { SFrameStore } from "../hooks/tools";

type Props = {
  children: React.ReactNode;
};
/**
 * Higher-order component that will block the app from rendering until a breakpoint no longer equals null.
 * WARNING: Component should only be used once per app.
 */
export const BreakpointGaurd: React.FC<Props> = ({ children }) => {
  const breakpoint = SFrameStore.use.breakpoint();
  return (
    <React.Fragment>{breakpoint === null ? null : children}</React.Fragment>
  );
};

import { Frame, InferFrames } from '@/lib/SFrame'
import { PrimaryButtonStyles } from '@/shared/components/'
import { colors } from '@/colors'
import { fonts } from '@/fonts'

export const styles = {
    parent: new Frame()
        .shared({
            background: '#191919',
            borderRadius: '15px',
            boxSizing: 'border-box',
            padding: '12px 12px 25px 12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            boxShadow: '2px 2px 30px rgba(0, 0, 0, 0.2)',
            backdropFilter: 'blur(40px)',
            width: '275px',
            cursor: 'pointer',
        })
        .mobile({
            width: '275px',
        }),
    image: new Frame().shared({
        width: '100%',
        height: '256px',
        borderRadius: '8px',
    }),
    title: new Frame().shared({
        fontFamily: fonts.dms['dms-bold'],
        color: colors['var(--white)'],
        fontSize: '24px',
        textAlign: 'center',
    }),
    button: {
        parent: new Frame()
            .shared({
                height: '48px',
                width: undefined,
                maxWidth: undefined,
                borderRadius: '8px',
            })
            .whileHover({
                background: 'rgba(255, 255, 255, 0.85)',
            }),
        text: new Frame().shared({
            color: '#0E0E0E',
            fontSize: '16px',
            fontFamily: fonts.dms['dms-bold'],
        }),
    } as InferFrames<typeof PrimaryButtonStyles>,
}

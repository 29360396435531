import { cachedNFTByOwner, clearNFTByOwner } from '@/lib/Cache'
import { useImmer } from 'use-immer'
import { useCallback, useEffect, useState } from 'react'
import { useLoggedIn, useLoginUser } from '@/app'
import { web3 } from '@releap/js-sdk'
import { useGetEventNftsQuery, useGetEventQuery, graphqlRequestClient, Nft } from '@/graphql/'
import { NFT } from '@releap/js-sdk'
import { useRPCConnection } from './useRPCConnection'
import { settings, torus, torusLogin } from '@/app'
import _ from 'lodash'
import { RaceGate } from '@/lib/racegate'
import { safeParseReleapServerError } from '@/utils/error/error'
import { toastStore } from '../components/toast/Toaster'

const getWalletNFTsGate = new RaceGate()

type Props = {
    clearCache?: boolean
} | void
export const useGetUserTickets = (props: Props) => {
    const { clearCache } = props ?? {}
    const [hookCompleted, setHookCompleted] = useState(false)

    const [pageNo, setPageNo] = useState(0)
    const [nfts, setNFTS] = useImmer<Record<string, Nft>>({})
    const [eventImage, setEventImage] = useState('')
    const [success, setSuccess] = useState(false)
    const [tickets, setTickets] = useState<NFT[] | null>(null)
    const [error, setError] = useState<unknown>()
    const [isError, setIsError] = useState(false)

    const toastSet = toastStore.use.set()

    const rpc = useRPCConnection()
    const { user, isLoggedIn } = useLoggedIn()

    const { login } = useLoginUser()
    const {
        data: event,
        isError: isErrorEvent,
        error: errorEvent,
    } = useGetEventQuery(
        graphqlRequestClient,
        { id: settings.event.id },
        {
            select: (data) => data.event,
            onError(err) {
                const cause = safeParseReleapServerError(err)
                if (!cause) {
                    toastSet({ type: 'error', message: 'Oops something went wrong!' })
                    return
                }
                toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
            },
        },
    )

    const { error: errorEventNFTs, isError: isErrorEventNfts } = useGetEventNftsQuery(
        graphqlRequestClient,
        {
            collectionInput: {
                collectionMints: [event?.ticketCollectionMint ?? ''],
                skip: 0,
                limit: 1,
            },
            NFTInput: {
                skip: pageNo * 20,
                limit: 20,
            },
        },
        {
            enabled: event?.ticketCollectionMint ? true : false,
            keepPreviousData: true,
            onSuccess(data) {
                const collection = data.collections?.result?.[0]
                const nfts = collection?.nfts?.result

                if (!eventImage && collection?.image) {
                    setEventImage(collection.image)
                }

                if (!nfts || nfts.length === 0) {
                    setSuccess(true)
                    return
                }
                setPageNo((old) => old + 1)
                setNFTS((draft) => {
                    nfts.forEach((nft) => {
                        if (nft != null && nft.mint != null) {
                            draft[nft.mint] = nft
                        }
                    })
                })
            },
            onError(err) {
                const cause = safeParseReleapServerError(err)
                if (!cause) {
                    toastSet({ type: 'error', message: 'Oops something went wrong!' })
                    return
                }
                toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
            },
        },
    )

    useEffect(() => {
        const fetch = () => {
            getWalletNFTsGate.lock({
                trycode: async () => {
                    if (!isLoggedIn || !user?.publicKey || !success) return
                    if (clearCache) clearNFTByOwner()

                    const publicKey = user.publicKey
                    const formattedPublicKey = new web3.PublicKey(publicKey)

                    const userWalletNFTs = ((await cachedNFTByOwner(rpc, formattedPublicKey)) as NFT[]).filter(
                        (nft) => nft.metadata.data.uri && nft.metadata.data.name,
                    )

                    const eventNFTs = userWalletNFTs.filter((walletNFT) => nfts[walletNFT.metadata.mint])

                    setTickets(eventNFTs)
                    setHookCompleted(true)
                },
                catchcode: (err) => {
                    setError(err)
                    setIsError(true)

                    const cause = safeParseReleapServerError(err)
                    if (!cause) {
                        toastSet({ type: 'error', message: 'Oops something went wrong!' })
                        return
                    }
                    toastSet({ type: 'error', message: cause.message ?? 'Oops something went wrong!' })
                },
            })
        }
        fetch()
    }, [isLoggedIn, success, clearCache])

    return {
        event,
        tickets,
        eventImage,
        isLoading: !hookCompleted,
        isError: isErrorEventNfts || isErrorEvent || isError,
        error: {
            error,
            errorEvent,
            errorEventNFTs,
        },
    }
}

import React from 'react'
import { styles } from './DesktopVerify.styles'
import adminBackground from '@/assets/admin-background.png'
import { Image } from '@/shared/styleComponents'
import { Text } from '@/shared/styleComponents'
import { PrimaryButton } from '@/shared/components/'
import { Ticket } from '@/graphql/'
import { Spinner } from '@/shared/styleComponents/spinner/Spinner'
import { ErrorIcon, SuccessIcon } from '../../icons'
import _ from 'lodash'
import { ComponentStatus } from '../../Verify'

const isStatus = (status: Props['status'], check: Props['status'][] | Props['status']) => {
    if (_.isArray(check)) {
        return check.includes(status)
    }

    return status === check
}

type Props = {
    status: ComponentStatus
    qrScanner: React.ReactElement
    verifiedTicket?: Ticket | null
    onScanAnother?: React.MouseEventHandler
    onVerify?: React.MouseEventHandler
}
export const DesktopVerify: React.FC<Props> = ({ status, qrScanner, verifiedTicket, onScanAnother, onVerify }) => {
    return (
        <React.Fragment>
            <Image src={adminBackground} overrides={styles.backgroundImage.provide()} />
            <div className="body" style={styles.body.parent.provide()}>
                <Text
                    color={isStatus(status, 'qrdetected') ? 'var(--success)' : 'var(--white)'}
                    overrides={{ opacity: isStatus(status, ['idle', 'qrdetected']) ? 1 : 0 }}
                >
                    {isStatus(status, 'qrdetected') ? 'QR code detected!' : 'No QR code detected'}
                </Text>
                <div className="qr-scanner" style={styles.body.qrScanner.parent.provide({ isIdle: status === 'idle' })}>
                    {isStatus(status, ['idle', 'qrdetected']) && (
                        <div
                            className="aim"
                            style={styles.body.qrScanner.aim.provide({ qrDetected: isStatus(status, 'qrdetected') })}
                        />
                    )}

                    {isStatus(status, ['idle', 'qrdetected']) ? (
                        qrScanner
                    ) : isStatus(status, 'loading') ? (
                        <Spinner size={66} color="var(--white)" secondaryColor="rgba(255, 255, 255, 0.1)" />
                    ) : isStatus(status, 'verfied') ? (
                        <div style={styles.body.status.icon.provide()}>
                            <SuccessIcon />
                            <Text fontSize={40} color="var(--success)" fontFamily="dms-bold">
                                Valid Ticket
                            </Text>
                        </div>
                    ) : isStatus(status, 'used') ? (
                        <div style={styles.body.status.icon.provide()}>
                            <ErrorIcon />
                            <Text fontSize={40} color="var(--danger)" fontFamily="dms-bold">
                                Ticket Used
                            </Text>
                        </div>
                    ) : isStatus(status, 'invalid') ? (
                        <div style={styles.body.status.icon.provide()}>
                            <ErrorIcon />
                            <Text fontSize={40} color="var(--danger)" fontFamily="dms-bold">
                                Invalid Ticket
                            </Text>
                        </div>
                    ) : (
                        isStatus(status, 'samecode') && (
                            <div style={styles.body.status.icon.provide()}>
                                <ErrorIcon />
                                <Text fontSize={40} color="var(--danger)" fontFamily="dms-bold" textAlign="center">
                                    Same Code Scanned
                                </Text>
                            </div>
                        )
                    )}
                </div>
                {isStatus(status, ['idle', 'qrdetected']) ? (
                    <PrimaryButton
                        disabled={isStatus(status, 'idle')}
                        text="Verify Ticket"
                        overrides={{
                            parent: styles.body.button?.parent?.provide(),
                            text: styles.body.button.text?.provide(),
                        }}
                        animation={styles.body.button.parent?.provideAnimation()}
                        onClick={(e) => onVerify?.(e)}
                    />
                ) : (
                    <PrimaryButton
                        text="Scan Another"
                        overrides={{
                            parent: styles.body.button?.parent?.provide(),
                            text: styles.body.button.text?.provide(),
                        }}
                        animation={styles.body.button.parent?.provideAnimation()}
                        onClick={(e) => onScanAnother?.(e)}
                    />
                )}
            </div>
        </React.Fragment>
    )
}

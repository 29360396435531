import { Frame } from "@/lib/SFrame";

export const styles = {
  parent: new Frame().shared({
    objectFit: "cover",
    width: "40px",
    height: "40px",
    borderRadius: "4px",
  }),
};

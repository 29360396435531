import { immerable } from 'immer'
import produce from 'immer'
import _, { over } from 'lodash'
import { CSSProperties } from 'react'

import { Store, BreakpointLimits, breakpointLimits, SFrameStore } from './hooks/tools'
import { setAutoFreeze } from 'immer'
import { shallowEqual } from './utils/engine'
import { Animatable } from './types/tools'
setAutoFreeze(false)

//TODO Add custom breakpoints

//#region Types
type States = Pick<Store, 'width' | 'height' | 'breakpoint'>

export type CSS<T> = CSSProperties | ((t: Partial<T>, screen: States) => CSSProperties)
export type Framer<T> = Record<string, any> | ((t: Partial<T>, screen: States) => Record<string, any>)

export type FrameIncludeState<T> = {
    criteria: string[]
    styles: CSS<T> | ((styles: CSS<T>, screen: States) => CSS<T>)
}

export type FrameCustomState<T> = {
    comparator: (w: number) => boolean
    styles: CSS<T> | ((styles: T, screen: States) => CSS<T>)
}[]

type BP = {
    [key in keyof BreakpointLimits]: BreakpointLimits[key]['name']
}

type BPLims = {
    [key in keyof BreakpointLimits]: BreakpointLimits[key]
}

export type FrameState<T> = {
    shared?: CSS<T> | ((styles: CSS<T>, screen: States) => CSS<T>)
    include?: FrameIncludeState<T>
    widescreen?: CSS<T> | ((styles: CSS<T>, screen: States) => CSS<T>)
    desktop?: CSS<T> | ((styles: CSS<T>, screen: States) => CSS<T>)
    mobile?: CSS<T> | ((styles: CSS<T>, screen: States) => CSS<T>)
    initial?: Framer<T> | ((styles: T, screen: States) => Framer<T>)
    animate?: Framer<T> | ((styles: T, screen: States) => Framer<T>)
    exit?: Framer<T> | ((styles: T, screen: States) => Framer<T>)
    whileHover?: Framer<T> | ((styles: T, screen: States) => Framer<T>)
    whileTap?: Framer<T> | ((styles: T, screen: States) => Framer<T>)
}
//#endregion

export class Frame<T extends { [key: string]: any }> {
    //#region class definitions
    [immerable] = true
    screen: States
    state: FrameState<T>
    provideHistory: {
        breakpoint: string
        state: Partial<T>
        output: CSSProperties
    }

    static BP: BP = {
        widescreen: breakpointLimits.widescreen.name,
        desktop: breakpointLimits.desktop.name,
        mobile: breakpointLimits.mobile.name,
    }

    static BPLims: BPLims = {
        widescreen: breakpointLimits.widescreen,
        desktop: breakpointLimits.desktop,
        mobile: breakpointLimits.mobile,
    }
    //#endregion

    constructor() {
        this.screen = {
            width: null,
            height: null,
            breakpoint: null,
        }
        this.state = {}
        this.provideHistory = {
            breakpoint: '',
            state: {},
            output: {},
        }
    }

    //#region provide css styles
    shared(styles: CSS<T>) {
        return produce(this, (draft) => {
            draft.state.shared = styles
        })
    }

    includes(criteria: (keyof BP)[], styles: CSS<T>) {
        // remove duplicates if any
        const _criteria = [...new Set(criteria)]
        return produce(this, (draft) => {
            draft.state.include = {
                criteria: _criteria,
                styles,
            }
        })
    }

    widescreen(styles: CSS<T>) {
        return produce(this, (draft) => {
            draft.state.widescreen = styles
        })
    }

    desktop(styles: CSS<T>) {
        return produce(this, (draft) => {
            draft.state.desktop = styles
        })
    }

    mobile(styles: CSS<T>) {
        return produce(this, (draft) => {
            draft.state.mobile = styles
        })
    }

    initial(styles: Framer<T>) {
        return produce(this, (draft) => {
            draft.state.initial = styles
        })
    }

    animate(styles: Framer<T>) {
        return produce(this, (draft) => {
            draft.state.animate = styles
        })
    }

    exit(styles: Framer<T>) {
        return produce(this, (draft) => {
            draft.state.exit = styles
        })
    }

    whileHover(styles: Framer<T>) {
        return produce(this, (draft) => {
            draft.state.whileHover = styles
        })
    }

    whileTap(styles: Framer<T>) {
        return produce(this, (draft) => {
            draft.state.whileTap = styles
        })
    }

    provide(
        inputState?: Partial<T> | null,
        override?: CSSProperties,
        debug?: {
            ID: string
            fn: (ID: string) => boolean
        },
    ): CSSProperties {
        if (debug && debug.fn(debug.ID)) {
            debugger
        }

        const state = inputState ?? {}
        const frame = produce(this, (draft) => {
            const width = SFrameStore.getState().width
            const height = SFrameStore.getState().height
            const breakpoint = SFrameStore.getState().breakpoint
            draft.screen = { breakpoint, width, height }
        })
        if (!frame.screen?.breakpoint) return {}

        // Provide method will only run if the breakpoint or state has changed from last render
        const hasOnlyShared = this.state?.shared && !this.state?.desktop && !this.state?.mobile
        const isBreakpointsEqual = this.provideHistory.breakpoint === frame.screen.breakpoint
        const isStateEqual = shallowEqual(state, this.provideHistory.state)
        const isHistoryEmpty = _.isEmpty(this.provideHistory.output)

        if ((isBreakpointsEqual && isStateEqual) || (hasOnlyShared && isStateEqual && !isHistoryEmpty)) {
            return { ...this.provideHistory.output, ...override }
        }

        const screen = frame.screen ?? {
            width: null,
            height: null,
            breakpoint: null,
            previousState: {},
        }
        const { shared, include, desktop, mobile, widescreen } = frame.state

        let result: CSSProperties = {}

        if (include && _.includes(include?.criteria, frame.screen.breakpoint)) {
            const out = _.isFunction(include.styles) ? include.styles(state, screen) : include.styles
            result = { ...result, ...out }
        }

        if (frame.screen.breakpoint === Frame.BP.widescreen && widescreen) {
            const out = _.isFunction(widescreen) ? widescreen(state, screen) : widescreen
            result = { ...result, ...out }
        }

        if (frame.screen.breakpoint === Frame.BP.desktop && desktop) {
            const out = _.isFunction(desktop) ? desktop(state, screen) : desktop
            result = { ...result, ...out }
        }

        if (frame.screen.breakpoint === Frame.BP.mobile && mobile) {
            const out = _.isFunction(mobile) ? mobile(state, screen) : mobile
            result = { ...result, ...out }
        }

        if (shared) {
            const out = _.isFunction(shared) ? shared(state, screen) : shared
            result = { ...result, ...out }
        }

        if (result.backdropFilter) {
            result.WebkitBackdropFilter = result.backdropFilter
        }

        this.provideHistory.breakpoint = frame.screen.breakpoint
        this.provideHistory.state = state ?? this.provideHistory.state
        this.provideHistory.output = result
        return { ...result, ...override }
    }
    //#endregion

    //#region Provide Framer animation styles
    provideAnimation(inputState?: Partial<T> | null, override?: Animatable) {
        const state = inputState ?? {}
        const frame = produce(this, (draft) => {
            const width = SFrameStore.getState().width
            const height = SFrameStore.getState().height
            const breakpoint = SFrameStore.getState().breakpoint
            draft.screen = { breakpoint, width, height }
        })

        if (!frame.screen?.breakpoint) return {} as any
        const screen = frame?.screen ?? {}

        const initial = frame.state.initial
        const animate = frame.state.animate
        const exit = frame.state.exit
        const whileHover = frame.state.whileHover
        const whileTap = frame.state.whileTap

        let out: FrameState<T> = {}

        if (initial) {
            out.initial = _.isFunction(initial) ? initial(state as T, screen) : initial
        }

        if (animate) {
            out.animate = _.isFunction(animate) ? animate(state as T, screen) : animate
        }

        if (exit) {
            out.exit = _.isFunction(exit) ? exit(state as T, screen) : exit
        }

        if (whileHover) {
            out.whileHover = _.isFunction(whileHover) ? whileHover(state as T, screen) : whileHover
        }

        if (whileTap) {
            out.whileTap = _.isFunction(whileTap) ? whileTap(state as T, screen) : whileTap
        }

        return { ...out, ...override }
    }
    //#endregion

    //#region ProvideSkeleton
    provideSkeleton(state?: Partial<T>, override?: CSSProperties): CSSProperties {
        const origin = this.provide(state, override)
        return {
            ...origin,
            height: origin.height ?? origin.lineHeight ?? origin.fontSize ?? '1em',
            padding: 'none',
            borderRadius: origin.borderRadius ?? '5px',
            width: origin.minWidth ?? origin.width ?? origin.maxWidth ?? '100px',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            overflow: 'hidden',
        }
    }
    //#endregion
}

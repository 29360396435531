import { Frame } from '@/lib/SFrame'
import { fonts } from '@/fonts'
import { colors } from '@/colors'

export const styles = {
    parent: new Frame()
        .shared({
            height: '56px',
            width: '100%',
            borderRadius: '12px',
            maxWidth: '545px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 16px',
            background: 'var(--white)',
            cursor: 'pointer',
            boxSizing: 'border-box',
        })
        .whileTap({
            scale: 0.98,
        }),
    text: new Frame().shared({
        fontSize: '20px',
        fontFamily: fonts.dms['dms-bold'],
        color: colors['var(--black)'],
    }),
    spinner: new Frame().shared({
        color: colors['var(--black)'],
        height: '30px',
        width: '30px',
    }),
}

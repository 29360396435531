import { Frame, InferFrames } from '@/lib/SFrame'
import { fonts } from '@/fonts'
import { colors } from '@/colors'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame()
        .shared({
            background: 'var(--background)',
            minHeight: '100vh',
            boxSizing: 'border-box',
        })
        .includes(['widescreen', 'desktop'], {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '64px',
        })
        .mobile({
            padding: '86px 0px 0px',
        }),
    backgroundImage: new Frame().shared({
        height: '100vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        objectFit: 'cover',
    }),
    hero: {
        parent: new Frame()
            .shared({
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                zIndex: 2,
                width: '100%',
                justifyContent: 'center',
            })
            .includes(['widescreen', 'desktop'], {
                gap: '50px',
            })
            .mobile({
                flexDirection: 'column',
                gap: '20px',
                padding: '0px 16px 70px',
                boxSizing: 'border-box',
            }),
        body: {
            parent: new Frame()
                .shared({
                    display: 'flex',
                    flexDirection: 'column',
                })
                .includes(['desktop', 'widescreen'], {
                    gap: '42px',
                    maxWidth: '650px',
                    width: '100%',
                })
                .mobile({
                    gap: '29px',
                    alignItems: 'center',
                }),
            title: {
                parent: new Frame()
                    .shared({
                        display: 'flex',
                        flexDirection: 'column',
                    })
                    .includes(['desktop', 'widescreen'], {
                        gap: '32px',
                    })
                    .mobile({
                        width: '100%',
                        gap: '10px'
                    }),
                mainText: new Frame()
                    .shared({
                        fontFamily: fonts.ppo['ppo-medium'],
                        color: colors['var(--white)'],
                    })
                    .includes(['desktop', 'widescreen'], {
                        fontSize: '72px',
                    })
                    .mobile({
                        fontSize: '20px',
                        textAlign: 'center',
                    }),
                subText: new Frame()
                    .shared({
                        fontFamily: fonts.dms['dms-medium'],
                        color: 'rgba(255, 255, 255, 0.35)',
                    })
                    .includes(['desktop', 'widescreen'], {
                        fontSize: '22px',
                    })
                    .mobile({
                        fontSize: '20px',
                        textAlign: 'center',
                        maxWidth: '800px',
                    }),
            },
            stats: {
                parent: new Frame().shared({
                    display: 'flex',
                    gap: '24px',
                }),
            },
            loggedInButton: {
                parent: new Frame()
                    .shared({
                        background: colors['var(--secondary)'],
                    })
                    .mobile({
                        width: '100%',
                        maxWidth: '455px',
                    }),
            } as InferFrames<typeof PrimaryButtonStyles>,
            notLoggedInButton: {
                parent: new Frame()
                    .shared({
                        background: colors['var(--white)'],
                    })
                    .mobile({
                        width: '100%',
                        maxWidth: '455px',
                    }),
            } as InferFrames<typeof PrimaryButtonStyles>,
        },
    },
}

export type Colors =
    | 'var(--background)'
    | 'var(--black)'
    | 'var(--white)'
    | 'var(--primary)'
    | 'var(--secondary)'
    | 'var(--danger)'
    | 'var(--success)'

type ColorMap = { [C in Colors]: C }
export const colors: ColorMap = {
    'var(--background)': 'var(--background)',
    'var(--black)': 'var(--black)',
    'var(--primary)': 'var(--primary)',
    'var(--secondary)': 'var(--secondary)',
    'var(--white)': 'var(--white)',
    'var(--danger)': 'var(--danger)',
    'var(--success)': 'var(--success)',
}

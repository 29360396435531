import { GraphQLClient } from 'graphql-request'
import { authStore } from '@/app'

// TODO Put url in env
// TODO Add headers function to dynamically added accesstoken headers
export const graphqlRequestClient = new GraphQLClient('https://api-staging.releap.io/graphql', {
    headers() {
        const accessToken = authStore.getState().accessToken
        if (accessToken) {
            return {
                Authorization: `Bearer ${accessToken}`,
            }
        }
    },
})

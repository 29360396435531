import { Frame, InferFrames } from '@/lib/SFrame'
import { colors } from '@/colors'
import { fonts } from '@/fonts'
import { PrimaryButtonStyles } from '@/shared/components/'

export const styles = {
    parent: new Frame().shared({
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    }),
    qrScanner: {
        parent: new Frame<{ isIdle: boolean }>().shared(({ isIdle }) => ({
            borderRadius: '22px',
            width: '100%',
            height: '100%',
            display: isIdle ? undefined : 'flex',
            alignItems: isIdle ? undefined : 'center',
            justifyContent: isIdle ? undefined : 'center',
            position: 'relative',
        })),
        aim: new Frame<{ qrDetected: boolean }>().shared(({ qrDetected }) => ({
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            height: '300px',
            width: '270px',
            border: qrDetected ? '4px solid rgba(0, 209, 140, 0.4)' : '4px solid rgba(249, 24, 128, 0.4)',
            borderRadius: '6px',
        })),
        scanner: new Frame().shared({
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }),
    },
    status: {
        loading: new Frame().shared({
            height: '66px',
            width: '66px',
        }),
        icon: new Frame().shared({
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '40px',
        }),
    },
    controls: {
        parent: new Frame().shared({
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '16px 16px 0px 0px',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            gap: '10px',
            paddingTop: '20px',
            paddingBottom: '20px'
        }),
        button: {
            parent: new Frame()
                .shared({
                    height: '40px',
                    maxWidth: '260px',
                    background: colors['var(--white)'],
                    borderRadius: '6px',
                })
                .whileHover({
                    background: 'rgba(255, 255, 255, 0.8)',
                }),
            text: new Frame().shared({
                color: colors['var(--black)'],
                fontSize: '16px',
                fontFamily: fonts.dms['dms-medium'],
            }),
        } as InferFrames<typeof PrimaryButtonStyles>,
        subButton: {
            parent: new Frame()
                .shared({
                    height: '35px',
                    maxWidth: '170px',
                    background: colors['var(--white)'],
                    borderRadius: '6px',
                })
                .whileHover({
                    background: 'rgba(255, 255, 255, 0.8)',
                }),
            text: new Frame().shared({
                color: colors['var(--black)'],
                fontSize: '14px',
                fontFamily: fonts.dms['dms-medium'],
            }),
        } as InferFrames<typeof PrimaryButtonStyles>,
    },
}
